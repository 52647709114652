import styled from 'styled-components';

const SelectorPanelOption = styled.div`
	display: flex;
	justify-content: space-between;
	border-top: 2px solid #f3f5f7;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
	padding-left: 0.5em;

	h3 {
		margin: 0;
		font-weight: 500;
    font-size: 0.80rem;
		margin-bottom: 0.1em;
	}

	h4 {
		margin: 0;
		color: #1457a5;
		font-weight: 100;
		font-size: 0.80rem;
		margin-right: 1em;
	}

	span {
		margin: 0;
		color: #565C67;
		font-weight: 100;
		font-size: 0.80rem;
	}
	
`;

const ColFlex = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%
`;

const RowFlex = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%
	// justify-content: space-between;
`;
export {
	SelectorPanelOption,
	RowFlex,
	ColFlex
};
