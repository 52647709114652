import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type InputModal = {
	open: boolean
};

const initialState: { data: InputModal } = {
	data: {
		open: false
	}
};

export const deleteModelSlice = createSlice({
	name: 'inputModelProvider',
	initialState,
	reducers: {
		toggleDeleteModalState(state, action: PayloadAction<{ value: boolean }>) {
			state.data.open = action.payload.value;
		}
	}
});

export const { toggleDeleteModalState } = deleteModelSlice.actions;

export default deleteModelSlice.reducer;