import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { setSaveWithPanelColorState } from 'store/slices/ToolSlice';
export default function SaveWithPanelColorToggle () {
	const { saveWithPanelColor } = useSelector((state: RootState) => state.toolNewPostions.data);
	
	const dispatch = useDispatch<AppDispatch>();
	const queriesRunningInBackGround = useQueriesRunningInBackGround();

	const handleClick = () => {
		dispatch(setSaveWithPanelColorState({ enabled: !saveWithPanelColor }));
	};
	return (
		<div className='switchButton showPanel'>
			Show panels with color after saving
			<div className="toggle-button-cover">
				<div className="button-cover">
					<div className="button b2" id="button-13">
						<input checked={saveWithPanelColor} onChange={handleClick} type="checkbox" className="checkbox" 
							disabled={queriesRunningInBackGround}/>
						<div className="knobs">
							<span></span>
						</div>
						<div className="layer"></div>
					</div>
				</div>
			</div>
		</div>
	);
}