import Button from 'components/Basic/Button';
import { MIN_ZOOM_LIMIT } from '../../constants';
import { useRefs } from 'contexts/RefContext';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { setZoomLevel } from 'store/slices/ToolSlice';
import { zoomAtAPosition } from 'components/tool/util';

export default function ZoomButtons() {
	const dispatch = useDispatch<AppDispatch>();
	const { konvaRef } =useRefs();
	const {zoomLevel}= useSelector((state: RootState)=>state.toolNewPostions.data);

	function handleZoomOnClick(type: 'in'| 'out'){
		if(!konvaRef?.current) return;
		const stage= konvaRef.current;
		const center = {
			x: stage.width() / 2,
			y: stage.height() / 2
		};
		const updatedZoomLevelAndPositon= zoomAtAPosition(stage, center, type);
		if(!updatedZoomLevelAndPositon) return;

		dispatch(setZoomLevel( updatedZoomLevelAndPositon));
	}

	return (
		<div style={{ 
			boxShadow: '0px 0.5px 1.5px #0000001a',
			backgroundColor:'#fff',
			// marginBottom:'10px',
			borderRadius: '3px'
		}}>
			<Button className='zoombtnHover' style={{
				fontSize: '15px',
				borderRadius: '0px',
				fontWeight: '600',
				background: 'transparent',
				border: 'transparent',
				color: '#666666',
				padding: '0.95em 0.85em',
			}} onClick={() => handleZoomOnClick('in')}
			>
				<svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M6.46574 5.63664H1.60938M6.46574 0.780273V5.63664V0.780273ZM6.46574 
          5.63664V10.493V5.63664ZM6.46574 5.63664H11.3221H6.46574Z" 
					stroke="#666666" strokeWidth="1.53359" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
			</Button>
			<span style={{
				height: '1px',
				width:'50%',
				margin:'0 auto',
				background: '#EBEBEB',
			}}></span>
			<Button className='zoombtnHover' style={{
				fontSize: '15px',
				borderRadius: '0px',
				fontWeight: '600',
				background: 'transparent',
				border: 'transparent',
				color: '#666666',
				padding: '1em 0.85em',
			}} onClick={() => handleZoomOnClick('out')}
			disabled={zoomLevel <= MIN_ZOOM_LIMIT}
			>
				<svg width="12" height="3" viewBox="0 0 12 3" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M10.8611 1.5625H7.54076H1.09766" stroke="#666666" strokeWidth="1.53359" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
			</Button>
		</div>
	);

}