import axios from 'axios';
import { createApiUrl } from '../util';

async function verifyToken(token: string) {
	const { data } = await axios.get(createApiUrl(`/organization/verify/${token}`));
	return data;
}

export {
	verifyToken
};