import { ButtonHTMLAttributes, ReactNode, forwardRef } from 'react';
import { ButtonStyle } from './styles';
import { Interpolation } from 'styled-components';
import CustomTooltip from '../Tooltip';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
	showLoader?: boolean,
	styles?: Interpolation<React.CSSProperties>;
	children: ReactNode;
	tooltipContent?: string;
}

const Button = forwardRef<HTMLButtonElement, Props>((props, ref ) => {
	const { showLoader, children, className, id, tooltipContent, ...rest } = props;
	const buttonClassName = `${className || ''} ${showLoader ? 'showLoader' : ''}`;
	return (
		<>
			<ButtonStyle
				id={id}
				className={buttonClassName}
				ref={ref}
				{...rest}
			>
				{children}
			</ButtonStyle>
			{!!tooltipContent && <CustomTooltip anchorSelect={`#${id}`} content={tooltipContent} />}
		</>
	);
});

Button.displayName = 'CustomButton';
export default Button;
