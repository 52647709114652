import { EnergyState } from 'store/slices/EnergySlice/types';
import { PanelTitle } from '../style';

interface Props {
	energy: EnergyState;
}

export default function Production({ energy }: Props) {
	return <PanelTitle>
		<h4>Production</h4>
		<h2>{energy.deratedEnergy.toFixed(2)} kWh</h2>
	</PanelTitle>;
}
