import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ModalPopup, ModalcontentInfo, ModalbuttonWrap, Modalbutton, ModalbuttonNo } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { toggleDeleteModalState } from 'store/slices/DeleteModalSlice';
import { setDeletedRoofIndexes, setUnfinalizedFacet } from 'store/slices/ToolSlice';
import { useRefs } from 'contexts/RefContext';
import { getPanelDrawerGroup } from 'components/tool/util';
import { Shape, ShapeConfig } from 'konva/lib/Shape';
import { Group } from 'konva/lib/Group';

const deleteMsg = 'Are you sure you want to delete the facet?';
export default function DeleteModal() {
	const { open } = useSelector((state: RootState) => state.deleteModal.data);
	const { selectedRoofIndex, deletedRoofIndexes, selectedUnfinalizedKonvaFacetId, newlyCreatedFacets , drawModeEnabled} =
		useSelector((state: RootState) => state.toolNewPostions.data);
	const dispatch = useDispatch();
	const { konvaRef } = useRefs();

	function hanldeConfirmation() {
		console.log('selectedRoofIndex',selectedRoofIndex);
		if (drawModeEnabled && konvaRef?.current) {
			deleteFacets();
		} else {
			if (!selectedRoofIndex) return;
			if (deletedRoofIndexes.includes(selectedRoofIndex)) return;
			dispatch(setDeletedRoofIndexes({ deletedRoofIndexes: [...deletedRoofIndexes, selectedRoofIndex] }));
		}
		dispatch(toggleDeleteModalState({ value: false }));
	}

	function findPolygonToDelete(node: Group | Shape<ShapeConfig>) {
		if (node.getClassName() !== 'Line') {
			return false;
		}
		if (selectedUnfinalizedKonvaFacetId) {
			return node._id === selectedUnfinalizedKonvaFacetId;
		}
		const lastCreatedFacetCoords = newlyCreatedFacets[newlyCreatedFacets.length - 1].hullCoords;
		const lastCreatedFacetPoints = lastCreatedFacetCoords.flat();
		return node.attrs.points.length === lastCreatedFacetPoints.length &&
			node.attrs?.points.every((p: number, index: number) => p === lastCreatedFacetPoints[index]);
	}

	function deleteFacets() {
		if (!konvaRef?.current) return;
		const group = getPanelDrawerGroup(konvaRef.current);
		const children = group?.getChildren();

		const polygon = children?.find(findPolygonToDelete);
		if (!polygon || !children?.length) {
			return;
		}
		const childrenToRemove: typeof children = [];

		children.forEach(child => {
			if (child.getClassName() === 'Circle' && polygon.attrs.points.includes(child.attrs.x) && polygon.attrs.points.includes(child.attrs.y)) {
				childrenToRemove.push(child);
			}
		});

		childrenToRemove.push(polygon);
		childrenToRemove.forEach((child) => child.remove());
		const newlyCreatedFacetsCopy = newlyCreatedFacets.filter(facet=>facet.konvaPolygonID !== polygon._id);
		dispatch(setUnfinalizedFacet({ id: null, newlyCreatedFacets: newlyCreatedFacetsCopy }));
	}

	return (
		<Popup
			contentStyle={{ width: '560px', borderRadius: '4px' }}
			open={open}
			closeOnDocumentClick={false}
			onClose={() => {
				if (open)
					dispatch(toggleDeleteModalState({ value: false }));
			}}>
			<ModalPopup>
				<ModalcontentInfo>
					{deleteMsg}
				</ModalcontentInfo>

				<ModalbuttonWrap>
					<Modalbutton onClick={hanldeConfirmation}>
						Yes
					</Modalbutton>
					<ModalbuttonNo onClick={() => {
						dispatch(setUnfinalizedFacet({id: null}));
						dispatch(toggleDeleteModalState({ value: false }));
					}}>
						No
					</ModalbuttonNo>
				</ModalbuttonWrap>
			</ModalPopup>
		</Popup>
	);
}