import { EnergyState } from 'store/slices/EnergySlice/types';
import { PanelTitle } from '../style';

interface Props {
	energy: EnergyState;
}

export default function SystemSize({ energy }: Props) {
	return <PanelTitle>
		<h4>System Size</h4>
		<h2>
			{`${(energy.totalNoOfPanelsSelectedByUser * energy.panelCapacityWatts).toFixed(3)} kW`}
		</h2>
	</PanelTitle>;
}
