import { useSelector } from 'react-redux';
import { RootState } from 'store';
import CreateBtn from './ButtonDiv/CreateBtn';
import { EditToolOptionsDiv, MainDiv, ToolBarWrapper } from './style';
import CalculateButton from './ButtonDiv/CalculateBtn';
import DrawBtn from './ButtonDiv/DrawBtn';
import EditToolOptions from './ButtonDiv/EditToolOptions';

export default function EditorToolBar() {
	const { showIrradiance } = useSelector((state: RootState) => state.Irradiance.data);
	
	if(showIrradiance){
		return null;
	}

	return (
		<MainDiv>
			<ToolBarWrapper>
				<EditToolOptionsDiv>
					<DrawBtn />
					<EditToolOptions/>
					<CreateBtn />
				</EditToolOptionsDiv>
				<CalculateButton />
			</ToolBarWrapper>
		</MainDiv>
	);
}