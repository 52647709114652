import styled from 'styled-components';

export const DropdownLayer= styled.div`
position: fixed;
background: #0000004f;
// width: 100%;
// height: 100%;
z-index: 99999;
top: 0;
left: 0;
// right: 0;
// bottom: 0;
`;

export const DropdownDiv= styled.div`
position: relative;
padding: 32px 28px 32px 28px;
font-size: 12px;
// background-color: #ffffff6b;
width: fit-content;
// border-radius: 6px;
display: grid;
grid-template-columns: 1fr;
gap: 10px;
// border: 1px solid rgba(255, 255, 255, 0.877);

button{
	background: #2e374b;
	border: 0;
	border-radius: 4px;
	padding: 10px 30px;
	letter-spacing: 1px;
	font-size: 14px;
	color: #fff;
	text-transform: capitalize;
	cursor: pointer;
	-webkit-transition: 0.3s ease;

}
`;

export const CloseBtn= styled.span`
position: absolute;
    right: 4px;
    top: 3px;

		svg {
			position: absolute;
			right: 4px;
			top: 4px;
			color: black;
			width: 20px;
			height: 20px;
			cursor: pointer;
		}
`;

