import Button from 'components/Basic/Button';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { ButtonHTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

type SaveDesignBtnProps = ButtonHTMLAttributes<HTMLButtonElement>
export default function SaveDesignBtn({ disabled, ...rest }: SaveDesignBtnProps) {
	const { uuid, org, roofDataChanging } = useSelector((state: RootState) => ({
		...state.roofData.data,
		roofDataChanging: state.roofData.configuringNewPanel || state.roofData.loading || state.roofData.switchingImageryProvider
	}));
	const saveDesignBtnText = org.setting?.dataForTool?.seeRecommendButtonText || 'Next';
	const allQueriesRunningInBackGround = useQueriesRunningInBackGround();
	const energyQueriesInProgrss = useQueriesRunningInBackGround(['save', `monthlyDetails-${uuid}`, `hourlyEnergy-${uuid}`]);
	const disableBtn = allQueriesRunningInBackGround || energyQueriesInProgrss || roofDataChanging || disabled;
	const showLoader = energyQueriesInProgrss;

	return <Button
		className="save-btn"
		disabled={disableBtn}
		showLoader={showLoader}
		{...rest}
	>
		{saveDesignBtnText}
	</Button>;
}