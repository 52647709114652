import axios from 'axios';
import { createApiUrl, getToken, generateQueryParams } from '../../util';

async function getDesignByExternalID(externalId: string, panelKey?: string) {
	const token = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	};
	const apiUrl = `/designs/design-by-externalId${generateQueryParams({ externalId, panelKey: panelKey ? encodeURIComponent(panelKey as string) : undefined })}`;
	const { data } = await axios.get(createApiUrl(apiUrl), config);
	return data as RawSolarQueryData;
}

async function getDesignByUUID(uuid: string, panelKey?: string, source: ImagerySource = 'sunroof') {
	const token = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	};
	const apiUrl = `/designs/design-by-uuid${generateQueryParams({ uuid, panelKey: panelKey ? encodeURIComponent(panelKey as string) : undefined, source })}`;
	const { data } = await axios.get(createApiUrl(apiUrl), config);
	return data as RawSolarQueryData;
}

export {
	getDesignByExternalID,
	getDesignByUUID, 
};
