import { Dispatch, SetStateAction } from 'react';
import BillInput from '../BillInput';
import { PanelTitle } from '../style';

interface BillInputProps {
	setError: Dispatch<SetStateAction<boolean>>;
}

export default function ElectricBillInput({ setError }: BillInputProps) {
	return <PanelTitle>
		<h4>Electric Bill/Mo</h4>
		<BillInput setError={setError} />
	</PanelTitle>;
}