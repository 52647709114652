import Button from 'components/Basic/Button';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { setIrrandiance } from 'store/slices/IrradianceSlice';
import { setEditState } from 'store/slices/ToolSlice';

export function IrradianceToggle() {
	const { showIrradiance } = useSelector((state: RootState) => state.Irradiance.data);
	const { threeDModelEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const dispatch = useDispatch();

	function handleIrradianceClick() {
		dispatch(setEditState({ enabled: false }));
		dispatch(setIrrandiance({ showIrradiance: !showIrradiance }));
	}

	return <Button style={{
		borderRadius: '20px',
		color: showIrradiance ? '#fff' : '#64686A',
		background: `${showIrradiance ? 'var(--primary)' : '#fff'}`,
		border: `${showIrradiance ? '1px solid var(--primary)' : '1px solid #C7CACF'}`
	}}
	onClick={handleIrradianceClick}
	disabled={threeDModelEnabled}
	className='btnFont0'>
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<ellipse cx="8.98856" cy="9.00452" rx="3.30497" ry="3.31898" fill={`${showIrradiance ? 'var(--primary)' : '#64686A'}`} />
			<ellipse cx="8.98856" cy="9.00452" rx="3.30497" ry="3.31898"
				stroke={showIrradiance ? '#fff' : '#003CFF'} strokeWidth="1.02958" />
			<ellipse cx="8.98856" cy="9.00452" rx="3.30497" ry="3.31898"
				stroke={showIrradiance ? '#fff' : '#64686A'} strokeWidth="1.02958" />
			<path d="M14.5469 9.26562H16.9998"
				stroke={showIrradiance ? '#fff' : '#003CFF'} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M14.5469 9.26562H16.9998"
				stroke={showIrradiance ? '#fff' : '#64686A'} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M1 9.29883H3.45291"
				stroke={showIrradiance ? '#fff' : '#003CFF'} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M1 9.29883H3.45291"
				stroke={showIrradiance ? '#fff' : '#64686A'} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M8.84766 14.5371L8.84766 17.0004"
				stroke={showIrradiance ? '#fff' : '#003CFF'} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M8.84766 14.5371L8.84766 17.0004"
				stroke={showIrradiance ? '#fff' : '#64686A'} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M9.05859 1L9.05859 3.4633"
				stroke={showIrradiance ? '#fff' : '#003CFF'} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M9.05859 1L9.05859 3.4633"
				stroke={showIrradiance ? '#fff' : '#64686A'} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M12.7344 13.127L14.4688 14.8688"
				stroke={showIrradiance ? '#fff' : '#003CFF'} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M12.7344 13.127L14.4688 14.8688"
				stroke={showIrradiance ? '#fff' : '#64686A'} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M3.13281 3.5293L4.86728 5.27111"
				stroke={showIrradiance ? '#fff' : '#003CFF'} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M3.13281 3.5293L4.86728 5.27111"
				stroke={showIrradiance ? '#fff' : '#64686A'} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M4.99219 12.8066L3.25772 14.5485"
				stroke={showIrradiance ? '#fff' : '#003CFF'} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M4.99219 12.8066L3.25772 14.5485"
				stroke={showIrradiance ? '#fff' : '#64686A'} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M14.6758 3.38477L12.9413 5.12658"
				stroke={showIrradiance ? '#fff' : '#003CFF'} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M14.6758 3.38477L12.9413 5.12658"
				stroke={showIrradiance ? '#fff' : '#64686A'} strokeWidth="1.54436" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
		<span>Irradiance</span>
	</Button>;

}