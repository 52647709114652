import { useQuery } from 'react-query';
import { getToken } from '../util';
import { verifyToken } from './api';
import Loader from '../components/Loader';
import Unauthorized from '../components/Unauthorized';
import { useDispatch,useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { setAuth } from 'store/slices/AuthSlice/authSlice';

type Props = {
	children: JSX.Element
}

export default function PrivateRoute(props: Props) {
	const { token } = useSelector((state: RootState) => state.authProvider.data);
	const dispatch = useDispatch<AppDispatch>();

	const tokenQuery = useQuery('myToken', async () => {
		// use local storage instead
		const myToken = getToken('Authorization');
		if (!myToken) return false;
		const data = await verifyToken(myToken);
		data && dispatch(setAuth({ token: myToken }));
		return data;
	}, { cacheTime: 0, enabled: false, refetchOnMount: false });

	if (!token) {
		tokenQuery.refetch();
	} else if(token && tokenQuery.isIdle) {
		return props.children;
	}

	if(tokenQuery.isLoading) {
		return <Loader/>;
	}

	if(!tokenQuery.data) {
		return <Unauthorized/>;
	}

	if(tokenQuery.data) {
		return props.children;
	}

	return null;

}