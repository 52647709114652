import { RoofData } from '../types';
import { makeRasterPanelKeyFromTopLeftCorner, numberInRange, suggestAllingmentBasedUponMaxPanelSizes, getPercentage } from '../../../../util';
import {
	ENERGY_THRESHOLD_THAT_A_PANEL_MUST_HAVE,
	IMAGERY_PROVIDER,
	IMAGERY_PROVIDER_KEY,
	MIN_PANELS_REQUIRED_FOR_ROOF_SEGMENT_TO_QUALIFY
} from '../../../../constants';

function seLectPanelsFromWHoleRoofSegIfItsOnlyOnePanelWasSelected(
	allPanelsSelectedByBillSize: RasterSolarPanel[],
	rasterRoofSegs: { [key: string]: RasterRoofSegment }
) {
	const roofSegmentObjSuggestedPanels = new Set<string>();
	const keys: string[] = [];
	for (let i = 0; i < allPanelsSelectedByBillSize.length; i++) {
		const index = allPanelsSelectedByBillSize[i].segmentId;
		if (!roofSegmentObjSuggestedPanels.has(index)) {
			roofSegmentObjSuggestedPanels.add(index);
			keys.push(index);
		}
	}
	let reselectPanels: RasterSolarPanel[] = [];
	keys.forEach(key => {
		reselectPanels = [...reselectPanels, ...rasterRoofSegs[key].panels];
	});
	return reselectPanels;
}

// >= 3 threshhold show acc to algo

// < 3 satifying  threshold show all

function getFilterFunction(toggleValue: boolean) {
	if (toggleValue) {
		return (roofSegs: RasterRoofSegment[]) => {
			return filterRasterSegmentsWithFlatRoof(roofSegs).filter(r => r.panelsCount > MIN_PANELS_REQUIRED_FOR_ROOF_SEGMENT_TO_QUALIFY);
		};
	}
	return filterRasterSegmentsWithAllfilterConditions;

}

function filterRasterSegments(roofSegs: RasterRoofSegment[]) {
	roofSegs.forEach(roofSeg => {
		if (roofSeg.panelsCount < 3) return;
		let energyToSubtract = 0;
		let panelsHavingEnergyHigherThenThresholdVal = false;

		for (let i = 0; i < roofSeg.panels.length; i++) {
			let count = 0;
			if (roofSeg.panels[i].yearlyEnergyDcKwh >= ENERGY_THRESHOLD_THAT_A_PANEL_MUST_HAVE)
				count += 1;

			if (count >= 3) {
				panelsHavingEnergyHigherThenThresholdVal = true;
				break;
			}

		}

		if (panelsHavingEnergyHigherThenThresholdVal) {
			roofSeg.panels = roofSeg.panels.filter(p => {
				if (p.yearlyEnergyDcKwh < ENERGY_THRESHOLD_THAT_A_PANEL_MUST_HAVE) {
					energyToSubtract += p.yearlyEnergyDcKwh;
					return false;
				}
				return true;
			});
			roofSeg.yearlyEnergyDcKwh -= energyToSubtract;
		}
	});
	return roofSegs.filter(roofSeg => roofSeg.panelsCount > MIN_PANELS_REQUIRED_FOR_ROOF_SEGMENT_TO_QUALIFY).filter(r => !r?.softDeleted);
}

function filterRasterSegmentsWithFlatRoof(roofSegs: RasterRoofSegment[]) {
	return roofSegs.filter(r => r.pitchDegrees > 8);
}

function filterRasterSegmentsWithAllfilterConditions(roofSegs: RasterRoofSegment[]) {
	const filteredRoofSegs = filterRasterSegments(roofSegs);
	return filteredRoofSegs.filter(roofSeg => {
		const validAzimuth = !(numberInRange(roofSeg.azimuthDegrees, 320, 360))
			&& !(numberInRange(roofSeg.azimuthDegrees, 0, 40));
		return validAzimuth && roofSeg.pitchDegrees > 8;
	});
}
function sortRoofSegments(roofSegs: RasterRoofSegment[]) {
	// if panelCount > 6 sort acc to azim which is closet to 180
	// else sort acc to yearly enrgy of roofSegment
	// roofSegs.sort((a, b) => {
	// 	if (a.panelsCount < 6 || b.panelsCount < 6) {
	// 		return b.yearlyEnergyDcKwh - a.yearlyEnergyDcKwh;
	// 	}
	// 	const aAzimDiff = Math.abs(a.azimuthDegrees - 180);
	// 	const bAzimDiff = Math.abs(b.azimuthDegrees - 180);
	// 	return aAzimDiff - bAzimDiff;
	// });
	// most effiecent roofSeg shows up first;
	roofSegs.sort((r1, r2) => {
		return r2.yearlyEnergyDcKwh / r2.panelsCount - r1.yearlyEnergyDcKwh / r1.panelsCount;
	});

	roofSegs.forEach(a => {
		a.panels = sortSolarPanels(a.panels);
	});

}

function convertUnslectedPanelsToSelectedPanels(allPanels: RasterSolarPanel[], unselectedPanels: string[]) {

	let totalMatchingPanels = 0;
	const unselectedPanelsCoordinate = new Set(unselectedPanels);
	const selectedPanels = [];

	for (let i = 0; i < allPanels.length; i++) {
		const exteriorCoord = makeRasterPanelKeyFromTopLeftCorner(allPanels[i].exteriorCoords[0]);
		if (unselectedPanelsCoordinate.has(exteriorCoord)) totalMatchingPanels++;
		else selectedPanels.push(allPanels[i].id);
	}
	// if not one of them belongs here that means panel config has changed
	// and we want to set the selected panels that are set by recomendation algo
	// find what to do when both have zero panels in them
	if (!totalMatchingPanels && !unselectedPanelsCoordinate.size) return [];
	if (totalMatchingPanels !== unselectedPanelsCoordinate.size) return [];
	return selectedPanels;
}

function checkIfAllSelectedPanelsExist(allPanels: RasterSolarPanel[], selectedPanels: string[]) {

	let totalMatchingPanels = 0;
	const selectedPanelsCoordinate = new Set(selectedPanels);

	for (let i = 0; i < allPanels.length; i++) {
		if (selectedPanelsCoordinate.has(allPanels[i].id)) totalMatchingPanels++;
	}
	// if not one of them belongs here that means panel config has changed
	// and we want to set the selected panels that are set by recomendation algo
	console.log('totalMatchingPan', selectedPanelsCoordinate, totalMatchingPanels);
	if (totalMatchingPanels !== selectedPanelsCoordinate.size) return [];
	return selectedPanels;
}
function getCenterCoord(e: number[][]) {
	const x = e.slice(0, 4).map(a => a[0]).reduce((p: number, c: number) => p + c, 0) / 4;
	const y = e.slice(0, 4).map(a => a[1]).reduce((p: number, c: number) => p + c, 0) / 4;
	return { x, y };
}

// If the result is negative, a is sorted before b.
// If the result is positive, b is sorted before a.
// If the result is 0, no changes are done with the sort order of the two values.

// take distance into consideration too while sorting on the basis of eneergy;
function sortSolarPanels(panels: RasterSolarPanel[]) {
	if (panels.length <= 1) return panels;
	const highestEnergyPanel = panels.reduce((p, c) => p.yearlyEnergyDcKwh > c.yearlyEnergyDcKwh ? p : c, panels[0]);
	const highestEnergyCoord = getCenterCoord(highestEnergyPanel.exteriorCoords);
	const distanceThreshhold = 3;
	const secondDistanceThreshold = 5;
	panels.sort((a, b) => {
		const centerCoorA = getCenterCoord(a.exteriorCoords);
		const centerCoorB = getCenterCoord(b.exteriorCoords);
		const distanceA = Math.sqrt((centerCoorA.x - highestEnergyCoord.x) ** 2 + (centerCoorA.y - highestEnergyCoord.y) ** 2);
		const distanceB = Math.sqrt((centerCoorB.x - highestEnergyCoord.x) ** 2 + (centerCoorB.y - highestEnergyCoord.y) ** 2);
		const bothPanelsAreNear = distanceA < distanceThreshhold && distanceB < distanceThreshhold;
		const onlyPanelAisCloser = distanceA < distanceThreshhold && distanceB > distanceThreshhold;
		const onlyPanelBisCloser = distanceA > distanceThreshhold && distanceB < distanceThreshhold;
		const energyAIsGreater = a.yearlyEnergyDcKwh >= b.yearlyEnergyDcKwh;
		const energyBIsGreater = !energyAIsGreater;

		if (bothPanelsAreNear) {
			const val = energyAIsGreater ? -1 : 1;
			return val;
		}

		if (onlyPanelAisCloser) {
			if (energyAIsGreater) return -1;
			if (distanceB < secondDistanceThreshold) return 1;
			return -1;
		}

		if (onlyPanelBisCloser) {
			if (energyBIsGreater) return 1;
			if (distanceA < secondDistanceThreshold) return -1;
			return 1;
		}

		const val = distanceA < distanceB ? -1 : 1;
		return val;
	});
	return panels;
}

export function convertDataToRoofContextData(
	roofData: RawSolarQueryData,
	annualEnergy: number,
	toggle: { orgToggle: boolean, designToggle: boolean },
	energyDerateRate: number,
	source: ImagerySource):
	RoofData {

	const imageryInfo = roofData[IMAGERY_PROVIDER_KEY[source] as keyof RawSolarQueryData] as SolarInfo;
	const allRoofSegs = structuredClone(roofData.designProcessedData.solarPanels.roofSegments);
	roofData.designProcessedData.solarPanels.roofSegments =
		getFilterFunction(toggle.orgToggle)(roofData.designProcessedData.solarPanels.roofSegments);

	// sorting acc to azim
	sortRoofSegments(roofData.designProcessedData.solarPanels.roofSegments);

	let allPanels: RasterSolarPanel[] = [];
	roofData.designProcessedData.solarPanels.roofSegments.map(rs => {
		allPanels = allPanels.concat(rs.panels.flat());
	});

	const rasterRoofSegs: { [key: string]: RasterRoofSegment } = {};
	roofData.designProcessedData.solarPanels.roofSegments.map(rs => {
		if (rs.panels.length) rasterRoofSegs[rs.panels[0].segmentId] = rs;
	});

	const {
		selectedPanelsIndex,
		unselectedPanelsIndex
	} = suggestAllingmentBasedUponMaxPanelSizes(
		allPanels.map(p => Math.floor(p.yearlyEnergyDcKwh)),
		// bumping up by 67% to counter out derating
		annualEnergy + getPercentage(annualEnergy, energyDerateRate), annualEnergy + getPercentage(annualEnergy, 50 + energyDerateRate)
	);

	// collection of slected and unslected
	console.log('allPanels length', allPanels.length);
	const commonPanels: RasterSolarPanel[] = toggle.designToggle ?
		[...allPanels] : [...selectedPanelsIndex, ...unselectedPanelsIndex].map(i => allPanels[i]);
	const newSuggestedPanels = seLectPanelsFromWHoleRoofSegIfItsOnlyOnePanelWasSelected(
		commonPanels, rasterRoofSegs,
	);

	// choose between selected or unslec panles
	// if slected panelssetBillAmount are not null choose them else choose unslected panels
	let selectedPanels = (roofData.selectedPanels?.length > 0) ?
		checkIfAllSelectedPanelsExist(allPanels, roofData.selectedPanels) :
		convertUnslectedPanelsToSelectedPanels(allPanels, roofData.unselectedPanels);

	// getting unselected panels on the basis of wheter is it first time that
	// we are generating them or are we getting them from the db

	const userHasSavedData = Object.keys(roofData.productionData).length !== 0;
	if (!userHasSavedData || !selectedPanels.length) {
		// dont over push it if we reach here
		selectedPanels = [];
		for (let i = 0; i < newSuggestedPanels.length; i++) {
			if (selectedPanelsIndex.includes(i))
				selectedPanels.push(newSuggestedPanels[i].id);
		}
	}

	const segsFromWhichUserSelectedPanels = new Set<string>();
	const roofSegsFromWhichUserHasSelectedPanels = [];
	for (let i = 0; i < newSuggestedPanels.length; i++) {
		const roofId = newSuggestedPanels[i].segmentId;
		if (!segsFromWhichUserSelectedPanels.has(roofId)) {
			roofSegsFromWhichUserHasSelectedPanels.push(roofId);
			segsFromWhichUserSelectedPanels.add(roofId);
		}
	}

	return {
		allPanels,
		jpgUrl: imageryInfo.imageUrl,
		dsmUrl: imageryInfo.dsmUrl,
		boundingBox: imageryInfo.metaData.boundingBox,
		latLng: imageryInfo.latLng,
		selectedPanels,
		uuid: roofData.designUUID,
		externalId: roofData.externalID,
		roofSegs: rasterRoofSegs,
		allRoofSegs,
		imgWidth: imageryInfo.metaData.imgWidth,
		imgHeight: imageryInfo.metaData.imgHeight,
		rasterSolarPanels: newSuggestedPanels,
		roofSegsFromWhichUserHasSelectedPanels,
		panel: {
			wattage: roofData.designProcessedData.solarPanels.wattage || 0.4,
			key: roofData.designProcessedData.solarPanels.panelTypeKey || '400W',
			height: roofData.designProcessedData.solarPanels.height,
			width: roofData.designProcessedData.solarPanels.width
		},
		org: {
			setting: {
				showToggleForAllPanels: roofData.organization.settings?.showToggleForAllPanels || false,
				acceptsAnnualEnergy: roofData.organization.settings?.acceptsAnnualEnergy || false,
				enableOffsetWarning: roofData.organization.settings?.enableOffsetWarning || false,
				isB2C: roofData.organization.settings?.isB2C || false,
				canEdit: !!roofData.organization.settings?.canEdit,
				canToggle3d: roofData.organization.settings?.canToggle3d !== undefined
					? roofData.organization.settings.canToggle3d
					: true,
				dataForTool: roofData.organization.settings?.dataForTool,
			},
			panelKeys: roofData.organization?.panelKeys || []
		},
		showAllPanels: roofData.showToggleForAllPanels,
		selectedPanelKey: roofData.panelKey,
		imagerySource: roofData.source || IMAGERY_PROVIDER.SUNROOF
	};
}

export function getPreviousPanelAllignment(
	roofData: RawSolarQueryData,
	annualEnergy: number,
	toggle: { orgToggle: boolean, designToggle: boolean },
	energyDerateRate: number,
	source: ImagerySource
):
	RoofData {
	const imageryInfo = roofData[IMAGERY_PROVIDER_KEY[source] as keyof RawSolarQueryData] as SolarInfo;
	const allRoofSegs = structuredClone(roofData.designProcessedData.solarPanels.roofSegments);
	roofData.designProcessedData.solarPanels.roofSegments =
		getFilterFunction(toggle.orgToggle)(roofData.designProcessedData.solarPanels.roofSegments);

	// sorting acc to azim
	sortRoofSegments(roofData.designProcessedData.solarPanels.roofSegments);

	let allPanels: RasterSolarPanel[] = [];
	roofData.designProcessedData.solarPanels.roofSegments.map(rs => {
		allPanels = allPanels.concat(rs.panels.flat());
	});

	const rasterRoofSegs: { [key: string]: RasterRoofSegment } = {};
	roofData.designProcessedData.solarPanels.roofSegments.map(rs => {
		if (rs.panels.length) rasterRoofSegs[rs.panels[0].segmentId] = rs;
	});

	const newSuggestedPanels = seLectPanelsFromWHoleRoofSegIfItsOnlyOnePanelWasSelected(
		allPanels, rasterRoofSegs,
	);

	const selectedPanels = (roofData.selectedPanels?.length > 0) ?
		checkIfAllSelectedPanelsExist(allPanels, roofData.selectedPanels) :
		convertUnslectedPanelsToSelectedPanels(allPanels, roofData.unselectedPanels);
	// when wont we get selected paensl if its either a new design or user has changed them
	if (!selectedPanels.length) {
		// doing this beacuase roofData.solarInfo.processedData.solarPanels.roofSegments is geting updated in this func
		roofData.designProcessedData.solarPanels.roofSegments = allRoofSegs;
		return convertDataToRoofContextData(roofData, annualEnergy, toggle, energyDerateRate, source);
	}
	const segsFromWhichUserSelectedPanels = new Set<string>();
	const roofSegsFromWhichUserHasSelectedPanels = [];
	for (let i = 0; i < newSuggestedPanels.length; i++) {
		const roofId = newSuggestedPanels[i].segmentId;
		if (!segsFromWhichUserSelectedPanels.has(roofId)) {
			roofSegsFromWhichUserHasSelectedPanels.push(roofId);
			segsFromWhichUserSelectedPanels.add(roofId);
		}
	}

	return {
		allPanels,
		jpgUrl: imageryInfo.imageUrl,
		dsmUrl: imageryInfo.dsmUrl,
		boundingBox: imageryInfo.metaData.boundingBox,
		latLng: imageryInfo.latLng,
		selectedPanels,
		uuid: roofData.designUUID,
		externalId: roofData.externalID,
		roofSegs: rasterRoofSegs,
		imgWidth: imageryInfo.metaData.imgWidth,
		imgHeight: imageryInfo.metaData.imgHeight,
		rasterSolarPanels: newSuggestedPanels,
		roofSegsFromWhichUserHasSelectedPanels,
		allRoofSegs,
		panel: {
			wattage: roofData.designProcessedData.solarPanels.wattage || 0.4,
			key: roofData.designProcessedData.solarPanels.panelTypeKey || '400W',
			height: roofData.designProcessedData.solarPanels.height,
			width: roofData.designProcessedData.solarPanels.width
		},
		org: {
			setting: {
				showToggleForAllPanels: roofData.organization.settings?.showToggleForAllPanels || false,
				acceptsAnnualEnergy: roofData.organization.settings?.acceptsAnnualEnergy || false,
				enableOffsetWarning: roofData.organization.settings?.enableOffsetWarning || false,
				isB2C: roofData.organization.settings?.isB2C || false,
				canEdit: !!roofData.organization.settings?.canEdit,
				canToggle3d: roofData.organization.settings?.canToggle3d !== undefined
					? roofData.organization.settings.canToggle3d
					: true,
				dataForTool: roofData.organization.settings?.dataForTool,
			},
			panelKeys: roofData.organization?.panelKeys || []
		},
		showAllPanels: roofData.showToggleForAllPanels,
		selectedPanelKey: roofData.panelKey,
		imagerySource: roofData.source || IMAGERY_PROVIDER.SUNROOF,
	};
}

export function reCalculateSelectedPanels(
	allPanels: RasterSolarPanel[], rasterRoofSegs: { [key: number]: RasterRoofSegment }, annualEnergy: number,
	toggle: { orgToggle: boolean, designToggle: boolean }, energyDerateRate: number) {
	const {
		selectedPanelsIndex,
		unselectedPanelsIndex
	} = suggestAllingmentBasedUponMaxPanelSizes(
		allPanels.map(p => Math.floor(p.yearlyEnergyDcKwh)),
		annualEnergy + getPercentage(annualEnergy, energyDerateRate), annualEnergy + getPercentage(annualEnergy, 50 + energyDerateRate)
	);

	// collection of slected and unslected
	console.log('allPanels length', allPanels.length);
	const commonPanels = toggle.designToggle ? [...allPanels] : [...selectedPanelsIndex, ...unselectedPanelsIndex].map(i => allPanels[i]);
	const newSuggestedPanels = seLectPanelsFromWHoleRoofSegIfItsOnlyOnePanelWasSelected(
		commonPanels, rasterRoofSegs,
	);

	const selectedPanels = [];
	for (let i = 0; i < newSuggestedPanels.length; i++) {
		if (selectedPanelsIndex.includes(i))
			selectedPanels.push(newSuggestedPanels[i].id);
	}

	const segsFromWhichUserSelectedPanels = new Set<string>();
	const roofSegsFromWhichUserHasSelectedPanels = [];
	for (let i = 0; i < newSuggestedPanels.length; i++) {
		const roofId = newSuggestedPanels[i].segmentId;
		if (!segsFromWhichUserSelectedPanels.has(roofId)) {
			roofSegsFromWhichUserHasSelectedPanels.push(roofId);
			segsFromWhichUserSelectedPanels.add(roofId);
		}
	}

	return {
		selectedPanels,
		rasterSolarPanels: newSuggestedPanels,
		roofSegsFromWhichUserHasSelectedPanels,
	};
}
