export function validateLatLng(qLatitude: string, qLongitude: string) {
	const latitude = parseFloat(qLatitude);
	const longitude = parseFloat(qLongitude);

	if (isNaN(latitude) || latitude < -90 || latitude > 90) {
		return false;
	}

	if (isNaN(longitude) || longitude < -180 || longitude > 180) {
		return false;
	}

	return true;
}