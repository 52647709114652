import Konva from 'konva';
import { useMemo } from 'react';

export default function useRasterDims(imgWidth: number,
	imgHeight: number,
	konvaRef: React.MutableRefObject<Konva.Stage | null> | null,
	maintainAspectRatio: boolean): Dimensions {

	const imgDims = useMemo(() => {
		if (konvaRef?.current) {
			const aspectRatio = imgWidth / imgHeight;
			return {
				height: konvaRef?.current?.height(),
				width: maintainAspectRatio ? konvaRef?.current?.height() * aspectRatio : konvaRef?.current?.width()
			};
		}
		return { height: imgHeight, width: imgWidth };
	}, [konvaRef?.current?.height(), konvaRef?.current?.width(), imgWidth, imgHeight]);
	return imgDims;
}
