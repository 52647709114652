import styled from 'styled-components';

export const Mapwrap = styled.div`
	 display:grid;
	 grid-template-rows: max-content auto;
	height:100%;
	width:100%;
	

	Button{
		white-space: nowrap;
	}

}
`;

export const SetBtnWrap = styled.div`
display:flex;
flex-direction: row;
position: absolute;
top: 0px;
z-index: 9;
background: #fff;
left: 0;
right: 0;
padding: 4px;
border-bottom: 1px solid #ccc;
box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
width: 100%;
align-items: center;
padding: 3px 20px;
justify-content: center;
box-sizing: border-box;

@media (max-width: 500px){
	text-align: left;
	padding: 3px 8px;
}

 h3{
	font-size: 20px;
	color: #4c4c4c;
	margin: 0px 24px 0px 0px;
	font-weight: 500;

	@media (max-width: 500px){
		font-size: 17px;
	}
 }

}

`;

export const MapHeader = styled.div`
	background-color:#fff;
	display:flex;
	justify-content:space-between;
	align-items: center;
	padding:0rem 0.5rem 1rem 0.5rem;
	flex-wrap:wrap;
	gap:0.5rem;

	>.right{
		display:flex;
		gap:0.5rem;
		align-items: center;
		flex-wrap:wrap;

		.info{
			font-size:0.85rem;
			color:#4E4E4E
		}
		.btn-container{
			display:flex;
			gap:0.5rem;
			flex-wrap:wrap;
		}
	}
	button{
		margin:0!important
	}
	h3{
		margin:0;
	}
	>.left{
		span{
			font-size:0.8rem;
			color:#4E4E4E;
			display: flex;
    	align-items: center;
    	gap: 0.3rem;
    	margin-top: 0.5em;
		}
		svg{
			stroke:#4E4E4E;
			width:1em;
		}
	}

	@media screen and (max-width:768px){
		>.right{
			width:100%;
			justify-content:flex-end;
		}
	}
	
	@media onlyscreen  and (max-width: 600px) {
		padding:0rem 0rem 1rem 0rem;
		>.right {
			justify-content:flex-start;
			gap: 0.5rem;
			button{
				width:100%;
			}
			span{
				font-size:0.9rem;
			}
		}
		.btn-container{
			width:100%;
		}
	}
`;

export const MapHeaderLeft = styled.div`
	background-color:yellow;
`;
export const MapHeaderRight = styled.div`
	background-color:greem;
`;
