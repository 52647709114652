import styled, { Interpolation } from 'styled-components';

type props = {
  styles?: Interpolation<React.CSSProperties>
}

const Switch = styled.label<props>`
position: relative;
display: inline-block;
width: 60px;
height: 34px;
${({ styles }) => styles}

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #bdbdbd;
  transition: 0.4s;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: auto;
    bottom: auto;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 100%;
  }

  input:checked + & {
    background-color: var(--primary);
  }

  input:checked + &:before {
    // transform: translate(75%);
  }

  &.round {
    border-radius: 34px;
  }
  
  &.round:before {
    border-radius: 50%;
  }
  
`;

export {
	Slider, 
	Switch
};