import Button from 'components/Basic/Button';
import { useRefs } from 'contexts/RefContext';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'store';
import { setMoveStage } from 'store/slices/ToolSlice';

export default function MoveButton() {
	const { moveStage } = useSelector((state: RootState) => state.toolNewPostions.data);
	const dispatch = useDispatch<AppDispatch>();
	const { konvaRef } = useRefs();

	return (
		<div>
			<Button className='zoombtnHover' style={{
				fontSize: '15px',
				borderRadius: '4px',
				fontWeight: '600',
				// background: 'transparent',
				background: `${moveStage ? '#feb517' : '#fff'}`,
				border: 'transparent',
				color: '#666666',
				padding: '0.95em 0.85em',
				boxShadow: '0px 0.5px 1.5px #0000001a',
				marginBottom:'10px'
			}} onClick={() => {	
				if(!konvaRef?.current) return;
				konvaRef.current.container().style.cursor = !moveStage? 'grab': 'default';
				dispatch(setMoveStage({ shouldMoveStage: !moveStage }));
			}}>
				<svg xmlns="http://www.w3.org/2000/svg"
				// fill="#666666"
					fill= {`${moveStage ? '#28373e' : '#666666'}`}
					width="15" version="1.1" 
					id="Capa_1" viewBox="0 0 232.439 232.439" xmlSpace="preserve">
					<g>
						<path d="M55.416,64.245c-2.804-1.162-6.027-0.52-8.174,1.625L2.197,
						110.915c-1.406,1.407-2.196,3.314-2.196,5.304   
						c0,1.989,0.79,3.896,2.196,5.304l45.045,45.045c1.436,1.435,3.354,
						2.196,5.306,2.196c0.966,0,1.941-0.186,2.868-0.571   
						c2.803-1.16,4.63-3.896,4.63-6.929v-90.09C60.046,68.141,
						58.219,65.405,55.416,64.245z M45.046,143.157l-26.938-26.938   
						L45.046,89.28V143.157z"/>
						<path d="M121.523,2.196C120.116,0.79,118.209,0,116.22,
						0c-1.989,0-3.896,0.79-5.304,2.196L65.871,47.241   
						c-2.145,2.146-2.786,5.371-1.625,8.174c1.16,2.803,3.896,
						4.63,6.929,4.63h90.09c3.033,0,5.769-1.827,6.929-4.63   
						c1.161-2.803,0.52-6.028-1.625-8.174L121.523,2.196z M89.281,45.045l26.938-26.938l26.939,26.938H89.281z"/>
						<path d="M230.242,110.915L185.197,
						65.87c-2.145-2.145-5.372-2.788-8.174-1.625c-2.803,1.16-4.63,
						3.896-4.63,6.929v90.09   c0,3.033,1.827,5.769,4.63,6.929c0.928,
						0.385,1.902,0.571,2.868,0.571c1.952,0,3.87-0.762,5.306-2.196l45.045-45.045 
						c1.406-1.407,2.196-3.314,2.196-5.304C232.438,114.229,
						231.648,112.322,230.242,110.915z M187.393,143.157V89.28l26.939,26.938   L187.393,143.157z"/>
						<path d="M161.263,172.393H71.175c-3.033,0-5.769,1.827-6.929,4.63c-1.161,
						2.803-0.52,6.028,1.625,8.174l45.045,45.046   c1.407,1.406,3.314,2.196,5.304,
						2.196c1.989,0,3.896-0.79,5.304-2.197l45.043-45.046c2.145-2.145,2.786-5.37,
						1.625-8.173   C167.031,174.221,164.296,172.393,161.263,172.393z M116.22,214.333l-26.938-26.939h53.876L116.22,214.333z"/>
					</g>
				</svg>
			</Button>
		</div>
	);

}