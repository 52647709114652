import axios from 'axios';
import { createApiUrl, getToken } from '../../util';

async function updateExceedOffsetPermission(uuid: string, key: string) {
	const myToken = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${myToken}`
		}
	};

	const requestBody = {
		key
	};

	const { data } = await axios.patch(createApiUrl(`/designs/change-exceed-offset-permission/${uuid}`), requestBody, config);
	return data;
}

export {
	updateExceedOffsetPermission,
};
