import { Image } from 'react-konva';
import useImage from 'use-image';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { useRefs } from 'contexts/RefContext';
import { setMouseState } from 'store/slices/ToolSlice';
import useRasterDims from 'hooks/useRasterDims';

export default function RasterImage() {

	const { jpgUrl, imgHeight, imgWidth, org } = useSelector((state: RootState) => state.roofData.data);
	const { konvaRef } = useRefs();
	const dispatch = useDispatch<AppDispatch>();
	const [image] = useImage(jpgUrl, 'anonymous', 'origin');
	const imgDims = useRasterDims( imgWidth, imgHeight, konvaRef, !!org?.setting?.canEdit);
		
	const handleMouseEnter = () => {
		dispatch(setMouseState({
			mouseOnMap: true
		}));
	};

	const handleMouseLeave = () => {
		dispatch(setMouseState({
			mouseOnMap: false
		}));
	};

	return (
		<Image
			image={image}
			preventDefault={false}
			height={imgDims.height}
			width={imgDims.width}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		/>
	);
}