import Button from 'components/Basic/Button';
import { checkPanelDrawerHasUnfinalizedFacets, deleteAllFacetsFromPanelDrawer } from 'components/tool/util';
import { useRefs } from 'contexts/RefContext';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'store';
import { setDrawModeState } from 'store/slices/ToolSlice';

export default function DrawBtn() {

	const { drawModeEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { konvaRef } = useRefs();
	const dispatch = useDispatch<AppDispatch>();

	function handleDrawFacetClick() {
		if (drawModeEnabled && konvaRef?.current && checkPanelDrawerHasUnfinalizedFacets(konvaRef.current)) {
			deleteAllFacetsFromPanelDrawer(konvaRef.current);
		}
		dispatch(setDrawModeState({ enabled: !drawModeEnabled }));
	}

	return (
		<Button style={{
			background: `${drawModeEnabled ? 'rgb(from #003CFF r g b / 10%)' : '#fff'}`,
			border: '0px',
			borderRight:'1px solid #C7CACF',
			borderLeft:'0px',
			color: `${drawModeEnabled ? '#003CFF' : '#28373E'}`,
			fontSize: '15px',
			padding: '0px',
			height: '36px',
			width: '48px',
			borderRadius:'0px'
				
		}} onClick={handleDrawFacetClick}>
			<svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M16.3841 8.69382L19.6807 14.1881H1L5.89256 6.49609H8.69204" 
					stroke={`${drawModeEnabled ? '#003CFF' : '#4A525A'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M16.4898 2.07375L17.5635 1L19.2903 2.72678L18.2572 3.75988M16.4898 
				2.07375L10.6564 7.90714L9.79297 10.4973L12.3831 9.63392L18.2572 3.75988M16.4898 
				2.07375L18.2572 3.75988" stroke={`${drawModeEnabled ? '#003CFF' : '#4A525A'}`} strokeWidth="1.6" strokeLinecap="round" 
				strokeLinejoin="round"/>
			</svg>

			{/* {drawModeEnabled ? 'Disable' : 'Enable'} */}
			{/* Draw Facet */}
		</Button>

	);

}