import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from './types';

const initialState: { data: AuthState } = {
	data:  {
		token: undefined,
	},
};

export const authProviderSlice = createSlice({
	name: 'authProvider',
	initialState,
	reducers: {
		setAuth(state, action: PayloadAction<{token:string}>) {
			const currentToken = {
				token: action.payload.token
			};
			state.data = currentToken;
		},
		deleteAuth(state, action: PayloadAction<{token:string}>) {
			const currentToken = {
				token: action.payload.token
			};
			state.data = currentToken;
		}
	},
});

export const { setAuth, deleteAuth } = authProviderSlice.actions;

export default authProviderSlice.reducer;