import { useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { debounce } from '../../../util';
import Input from 'components/Basic/Input';
import { updateDesignAnnualEnergy } from '../api';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { suggestNewPanelAlignment, recalcuatedEnergyVals } from 'store/slices/RoofDataSlice/roofDataSlice';
import { EnergyState } from 'store/slices/EnergySlice/types';
import { SUBHUB_APP_NAME } from '../../../constants';
import { setThreeDState } from 'store/slices/ToolSlice';

type Props = {
	setError: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AnnualEnergyInput(props: Props) {
	const [annualEnergyError, setAnnualEnergyError] = useState('');
	const { annualInputEnergy } = useSelector((state: RootState) => state.energyData.data as unknown as EnergyState);
	const { uuid } = useSelector((state: RootState) => state.roofData.data);
	const annualEnergyRef = useRef<number>(annualInputEnergy);
	const dispatch = useDispatch<AppDispatch>();
	const { parentAppName } = useSelector((state: RootState) => state.QueryParams.data);
	const { threeDModelEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const disable3D = () => {
		dispatch(setThreeDState({ enabled: false })); 
	};
	const iframeIsEmbeddedInSubhub = parentAppName === SUBHUB_APP_NAME;

	const { setError } = props;

	const updateAnnualEnergyQuery = useQuery(
		`updateAnnualEnergy-${uuid}`,
		async () => {
			if (annualEnergyError) return;
			const requestData = await updateDesignAnnualEnergy(
				uuid,
				annualEnergyRef.current
			);
			return requestData;
		},
		{
			cacheTime: 0,
			refetchOnWindowFocus: false,
			enabled: false,
			retry: false,
			onError: () => {
				toast.error('Invalid Annual Energy');
			},
		}
	);

	const debouncedChangeAnnualEnergy = useMemo(
		() =>
			debounce(() => {
				const dispatchFunc = iframeIsEmbeddedInSubhub ? recalcuatedEnergyVals : suggestNewPanelAlignment;
				dispatch(dispatchFunc({energy: +annualEnergyRef.current}));
				updateAnnualEnergyQuery.refetch();
			}, 700),
		[]
	);

	return (
		<Input
			key={annualInputEnergy}
			error={annualEnergyError}
			defaultValue={annualInputEnergy}
			disabled={iframeIsEmbeddedInSubhub}
			onChange={(event) => {
				const value = +event.target.value.trim();
				if(threeDModelEnabled)
					disable3D();
				annualEnergyRef.current = value;

				if (isNaN(value)) {
					setAnnualEnergyError('annual energy must be number.');
					setError(true);
					return;
				}
				// value < 50 || value > 10000
				if (value < 1) {
					setAnnualEnergyError(' ');
					setError(true);
					return;
				}
				setAnnualEnergyError('');
				setError(false);
				debouncedChangeAnnualEnergy();
			}}
		/>
	);
}