
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { updateExceedOffsetPermission } from './api';
import { ModalPopup, ModalcontentInfo, ModalbuttonWrap, Modalbutton, ModalbuttonNo } from './styles';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { toggleOffsetState, updateUserPermission } from 'store/slices/OffsetModelSlice/offsetmodelSlice';
import { updateEnergyOnUserConfirmation } from 'store/slices/EnergySlice/energySlice';

const messageFOr120Offset = `Are you sure you want to exceed a 120% offset?\n
 We do not recommend this unless you are planning to get a pool, electric car, or increase usage.`;
const messageFOr150Offset = `We do not recommend going above a 150% offset. We cannot guarantee that your utility company will approve your system at that size.\n
 If you have any questions, please call us at (866) 828-1697.`;

export default function OffsetModal() {
	const { open, userHasGivenPermToExceedOffset } = useSelector((state: RootState) => state.offsetModal.data);
	const { uuid, rasterSolarPanels } = useSelector((state: RootState) => state.roofData.data);
	const dispatch = useDispatch();

	const userHasGivenPermToExceed120Offset = userHasGivenPermToExceedOffset.offset120;
	const key = userHasGivenPermToExceed120Offset ? 'offset150' : 'offset120';

	const offsetModalQuery = useQuery('offsetModal', async () => {
		await updateExceedOffsetPermission(uuid, key);
	}, {
		onSuccess: () => {
			dispatch(toggleOffsetState({ value: false }));
			dispatch(updateUserPermission({ type: key, value: true }));
			if (userHasGivenPermToExceedOffset?.value) {
				dispatch(
					updateEnergyOnUserConfirmation({
						rasterSolarPanels,
						val: userHasGivenPermToExceedOffset.value,
					})
				);
			}
		},
		onError: () => {
			toast.error('something went wrong, please try again');
		},
		enabled: false
	});

	return (
		<Popup
			contentStyle={{ width: '560px', borderRadius: '4px' }}
			open={open}
			closeOnDocumentClick={false}
			onClose={() => {
				if (open)
					dispatch(toggleOffsetState({ value: false }));
			}}>
			<ModalPopup>
				<ModalcontentInfo>
					{userHasGivenPermToExceed120Offset ? messageFOr150Offset : messageFOr120Offset}
				</ModalcontentInfo>

				<ModalbuttonWrap>
					<Modalbutton onClick={async () => {
						offsetModalQuery.refetch();
					}
					}>
						Yes
					</Modalbutton>
					<ModalbuttonNo onClick={() => {
						dispatch(toggleOffsetState({ value: false }));
					}}>
						No
					</ModalbuttonNo>
				</ModalbuttonWrap>
			</ModalPopup>
		</Popup>
	);
}