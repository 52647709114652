import { shallowMemoize, getDeratedValue, getPercentage } from '../../util';
import { EnergyState } from 'store/slices/EnergySlice/types';
import { Design, HourlyEngeryTOFAndSolarAccessData, perPanelEnergyDetails, TOFAndSolarAccessPerSgement } from './types';
import { DEFAULT_KONVA_SCALE, DEFAULT_KONVA_STAGE_POSITION, IRRADIANCE_UPRATE_RATE, MAX_ZOOM_LIMIT_FOR_SAVE, PANEL_OFF_COLOR } from '../../constants';
import { getAllKonvaGroups, getPanelDrawerGroup, setZoomLevelOnStageFromCenter } from 'components/tool/util';
import Konva from 'konva';

export const getEnergyData = shallowMemoize(function returnProductionData(
	energy: EnergyState, roofSegsFromWhichUserHasSelectedPanels: string[],
	roofSegs: { [key: string]: RasterRoofSegment }, offset: number, panel: { wattage: number, key: string },
	panelsForMonthlyCalculation: { [key: string]: perPanelEnergyDetails } | undefined, designUUID: string, energyDerateRate: number,
	hourlyEngeryTOFAndSolarAccessData: HourlyEngeryTOFAndSolarAccessData | undefined) {

	const facets: {
		pitchDegrees: number;
		azimuthDegrees: number;
		panelsCount: number;
		yearlyEnergyDcKwh: number;
		monthlyEnergyDcKwh: number[];
		orientation: Orientation | 'MIXED';
		solarAccess: number;
		solarAccessMonthly: number[];
		irradiance: number;
		annualTOF: number;
		annualTSRF: number;
	}[] = [];

	const { selectedPanels, totalNoOfPanelsSelectedByUser, panelCapacityWatts, deratedEnergy, currentBillAmount, annualInputEnergy } = energy;
	let yearlyEnergy = 0, monthlyEnergy = 0, monthlyDataAvailable = true, designIrradiance = 0;
	const tOFAndSolarAccessData = reStructureTOFAndSolarAccessData(hourlyEngeryTOFAndSolarAccessData);

	roofSegsFromWhichUserHasSelectedPanels.forEach((roofId) => {
		const roofSeg = roofSegs[roofId] as RasterRoofSegment & { simplifiedHullCoords: number[][] };
		if (!roofSeg) return;
		if (!panelsForMonthlyCalculation || Object.keys(panelsForMonthlyCalculation).length === 0)
			monthlyDataAvailable = false;

		const maxPanelEnergyForEachMonth = new Array(12).fill(0);
		let yearlyEnergyDcKwh = 0, panelsCount = 0, selectedPanelsIrradiance = 0;
		const monthlyEnergyDcKwh = new Array(12).fill(0);
		roofSeg.panels.forEach((panel) => {
			// Calculating maxPanelEnergyForEachMonth as we want overall max energy from all panels(selected as well as unselected)
			if (monthlyDataAvailable) {
				const { monthlyEnergy: monthlyEnergies = [] } = panelsForMonthlyCalculation?.[panel.id] || {};
				monthlyEnergies?.forEach((monthEnergy: number, index: number) => {
					maxPanelEnergyForEachMonth[index] = Math.max(+(maxPanelEnergyForEachMonth[index]), +(monthEnergy));
				});
			}
			if (selectedPanels.includes(panel.id)) {
				if (monthlyDataAvailable) {
					const { monthlyEnergy: monthlyEnergies = [] } = panelsForMonthlyCalculation?.[panel.id] || {};
					monthlyEnergies?.forEach((monthEnergy: number, index: number) => {
						monthlyEnergyDcKwh[index] += Number(monthEnergy);
						monthlyEnergy += Number(monthEnergy);
					});
				}
				yearlyEnergyDcKwh += panel.yearlyEnergyDcKwh;
				panelsCount++;
				selectedPanelsIrradiance += panel.irradiance;
			}
		});
		// Solar Access Calculations
		const tsrfTofAndSolarAccessDataPerFacet = getTsrfTofAndSolarAccessDataPerFacet(tOFAndSolarAccessData, selectedPanels, roofId);
		yearlyEnergy += yearlyEnergyDcKwh;
		designIrradiance += selectedPanelsIrradiance;

		facets.push({
			yearlyEnergyDcKwh: getDeratedValue(yearlyEnergyDcKwh, energyDerateRate),
			monthlyEnergyDcKwh: monthlyEnergyDcKwh.map(mE => getDeratedValue(mE, energyDerateRate)),
			azimuthDegrees: roofSeg.azimuthDegrees,
			pitchDegrees: roofSeg.pitchDegrees,
			orientation: roofSeg.orientation,
			panelsCount,
			irradiance: (selectedPanelsIrradiance + getPercentage(selectedPanelsIrradiance, IRRADIANCE_UPRATE_RATE)) / panelsCount,
			...tsrfTofAndSolarAccessDataPerFacet,
		});
	});

	console.log('facets', facets);
	const hourlyEnergy = getHouseHourlyEngery(hourlyEngeryTOFAndSolarAccessData, selectedPanels, energyDerateRate, deratedEnergy);

	const design: Design = {
		yearlyEnergy: getDeratedValue(yearlyEnergy, energyDerateRate),
		monthlyEnergy: getDeratedValue(monthlyEnergy, energyDerateRate),
		irradiance: (designIrradiance + getPercentage(designIrradiance, IRRADIANCE_UPRATE_RATE)) / totalNoOfPanelsSelectedByUser,
		facets,
		systemSize: (totalNoOfPanelsSelectedByUser * panelCapacityWatts),
		energy: deratedEnergy,
		offset,
		totalPanels: totalNoOfPanelsSelectedByUser,
		billAmount: currentBillAmount,
		panelKey: panel.key,
		panel,
		designUUID,
		annualUsage: annualInputEnergy,
		hourlyEnergy,
	};

	console.log(` 
			design attributes with derate factor  ${energyDerateRate} totalPanelsSelected ${totalNoOfPanelsSelectedByUser}\n
			deratedYearlyEnergy ${design.yearlyEnergy} , normalYearlyEnergy ${yearlyEnergy} \n
			deratedMontylyEnergy ${design.monthlyEnergy} , normalMonthlyEnergy ${monthlyEnergy} \n
			average upratedIrradiance ${design.irradiance} , avergae normalIrradiance ${designIrradiance / totalNoOfPanelsSelectedByUser} \n
			uprate irradiance rate ${IRRADIANCE_UPRATE_RATE}
	`);

	return {
		selectedPanels,
		design
	};
});

function getHouseHourlyEngery(
	hourlyEngeryTOFAndSolarAccessData: HourlyEngeryTOFAndSolarAccessData | undefined,
	selectedPanels: string[], energyDerateRate: number, annualEnergy: number) {
	
	const allSegmentsHourlyEnergy = hourlyEngeryTOFAndSolarAccessData?.hourlyProductionData || [];

	if (!allSegmentsHourlyEnergy.length || !selectedPanels.length) {
		return [];
	}

	let houseHourlyEnergy = new Array(8760).fill(0);
	let totalYearhourlyEnergy = 0, panelCount = 0;

	for (let i = 0; i < allSegmentsHourlyEnergy.length; i++) {
		const hourlyEnergyPerSegment = allSegmentsHourlyEnergy[i];
		const hourlyEnergyPerPanel = hourlyEnergyPerSegment.hourlyProduction;

		for (const [pID, energyData] of Object.entries(hourlyEnergyPerPanel)) {
			if (selectedPanels.includes(pID)) {
				panelCount += 1;
				energyData.hourlyProduction.forEach((production: number, index: number) => {
					const deratedEnergy = (getDeratedValue(production, energyDerateRate));
					houseHourlyEnergy[index] += deratedEnergy;
					totalYearhourlyEnergy += deratedEnergy;
				});
			}

			if (panelCount === selectedPanels.length) {
				break;
			}
		}

		if (panelCount === selectedPanels.length) {
			break;
		}
	}

	if(!totalYearhourlyEnergy) return houseHourlyEnergy;

	const ratio = annualEnergy / totalYearhourlyEnergy;
	totalYearhourlyEnergy = 0;

	houseHourlyEnergy = houseHourlyEnergy.map((e: number) => {
		e = e * (ratio);
		totalYearhourlyEnergy += e;
		return e;
	});
	
	console.log({
		'Hourly_Energy': houseHourlyEnergy,
		'Total_Hourly_Energy': totalYearhourlyEnergy
	});

	return houseHourlyEnergy;
}

function getTsrfTofAndSolarAccessDataPerFacet(
	tOFAndSolarAccessData: Record<string, TOFAndSolarAccessPerSgement>,
	selectedPanels: string[],
	roofId: string
) {
	const defaultTsrfTofAndSolarAccessData = {
		solarAccess: 0,
		solarAccessMonthly: new Array(12).fill(0),
		annualTOF: 0,
		annualTSRF: 0
	};
	if (!Object.keys(tOFAndSolarAccessData)?.length) {
		return defaultTsrfTofAndSolarAccessData;
	}

	const tofAndSolarAccessPerSegment = tOFAndSolarAccessData[roofId];
	const { annualTOF, solarAccessByPanel } = tofAndSolarAccessPerSegment;
	let totalAnnualSolarAccess = 0, panelCount = 0;
	const totalMonthlySolarAccess = new Array(12).fill(0) as number[];
	Object.entries(solarAccessByPanel).forEach(solrAccess => {
		const [panelID, solarAccessPerPanel] = solrAccess;
		if (selectedPanels.includes(panelID)) {
			panelCount += 1;
			totalAnnualSolarAccess += solarAccessPerPanel.annualSolarAccess;
			solarAccessPerPanel.monthlySolarAccess.forEach((val, index) => totalMonthlySolarAccess[index] += val);
		}
	});

	if (!panelCount) {
		return defaultTsrfTofAndSolarAccessData;
	}

	const avgAnnualSolarAccess = totalAnnualSolarAccess / panelCount;
	const avgMonthlySolarAccess = totalMonthlySolarAccess.map((total) => total / panelCount);
	const annualTSRF = (annualTOF * avgAnnualSolarAccess) / 100;

	return {
		solarAccess: avgAnnualSolarAccess,
		solarAccessMonthly: avgMonthlySolarAccess,
		annualTOF,
		annualTSRF
	};
}

function reStructureTOFAndSolarAccessData(hourlyEngeryTOFAndSolarAccessData: HourlyEngeryTOFAndSolarAccessData | undefined) {
	const tOFAndSolarAccessObj: Record<string, TOFAndSolarAccessPerSgement> = {};
	if (!hourlyEngeryTOFAndSolarAccessData || !hourlyEngeryTOFAndSolarAccessData?.tofAndSolarAccess?.length) {
		return tOFAndSolarAccessObj;
	}
	const tofAndSolarAccessPerSegment = hourlyEngeryTOFAndSolarAccessData.tofAndSolarAccess;
	tofAndSolarAccessPerSegment.forEach(s => tOFAndSolarAccessObj[s.segmentId] = s);
	return tOFAndSolarAccessObj;
}

// makes unselected invlisble
export function changeVisibilityOfPanelsAndGetCanvasImage(konvaObj: Konva.Stage, visiblily: boolean) {
	const panelObjs = getAllKonvaGroups(konvaObj)?.map(g => g.children).flat();
	if (!panelObjs) return;
	for (let i = 0; i < panelObjs?.length; i++) {
		const line = panelObjs[i] as Konva.Line;
		if (line.attrs['fill'] === PANEL_OFF_COLOR)
			line.visible(visiblily);
	}
	return konvaObj.toDataURL();
}

// get abosulte postions after translations
export function getAbsolutePoints(shape: Konva.Line, scale: Vector2d, stagePos: Vector2d) {
	// Get the shape's absolute transformation matrix
	const transform = shape.getAbsoluteTransform().decompose();

	// Get the original points of the shape
	const points = shape.points();

	const newTransform = new Konva.Transform()
		.scale(1, 1)
		.translate((transform.x - stagePos.x) / transform.scaleX, (transform.y - stagePos.y) / transform.scaleY)
		.rotate(transform.rotation * (Math.PI / 180))
		.skew(transform.skewX, transform.skewY);

	// Calculate the new absolute positions of the points
	const absolutePoints = [];
	for (let i = 0; i < points.length; i += 2) {
		const point = { x: points[i], y: points[i + 1] };
		// Apply the transformation to each point
		const transformedPoint = newTransform.point(point);
		absolutePoints.push(transformedPoint.x / scale.x, transformedPoint.y /scale.y);
	}

	return absolutePoints;
}

export function transformPointsToOriginalScale(scale: Vector2d, point: number[]) {
	const {x, y} = scale;
	return [point[0] / x, point[1] / y];
}

export function changeVisibilityOfPanelsAndGetCanvasImageForThreeMode(konvaObj: Konva.Stage, fullViewEnabled: boolean) {
	const scale= { x: DEFAULT_KONVA_SCALE, y: DEFAULT_KONVA_SCALE };
	if(fullViewEnabled){
		const raster= getPanelDrawerGroup(konvaObj)?.find('Image')[0] as Konva.Image;
		scale.x = konvaObj.width()/raster.width();
		scale.y = konvaObj.height()/ raster.height();
	}
	//Not setting state for this since these are temprory whe n 3D is enabled.
	konvaObj.position(DEFAULT_KONVA_STAGE_POSITION);
	konvaObj.scale(scale);

	return changeVisibilityOfPanelsAndGetCanvasImage(konvaObj, false);

}

export function adjustScalingWhileSavingDesignImage(konvaObj: Konva.Stage) {
	//Not setting state for this since these are temprory whe n saving canvas image
	const scale= setZoomlevelOnSave(konvaObj.scaleX());
	konvaObj.scale({ x: scale, y: scale });
	if (scale > DEFAULT_KONVA_SCALE) {
		setZoomLevelOnStageFromCenter(konvaObj);
	} 
}

export function setZoomlevelOnSave(zoom: number){
	if (zoom < DEFAULT_KONVA_SCALE) return DEFAULT_KONVA_SCALE;
	else if (zoom > MAX_ZOOM_LIMIT_FOR_SAVE) return MAX_ZOOM_LIMIT_FOR_SAVE;
	return zoom;
}
