import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IrradianceState } from './type';
import { getFluxData } from 'components/DisplayEnergy/api';
import { ErrorResponseHandler } from 'store/api/AxiosError';
import { AxiosError } from 'axios';
import { CustomError } from 'store/api/types';
import { changePanel, fetchRoofData } from '../RoofDataSlice/roofDataSlice';

const initialState: { data: IrradianceState } = {
	data: {
		selectedMonth: 12,
		showIrradiance: false,
		irradianceImages: [],
		showProgress: false,
		showPanels: true
	},
};

export const fetchIrradianceImages = createAsyncThunk('irradiance/fetchImages',
	async ({uuid}: FetchIrradianceImagesArgs, thunkApi) => {
		try {
			return await getFluxData(uuid) as string[];
		} catch (error) {
			console.log('error irreadiance slice',error);
			throw thunkApi.rejectWithValue(ErrorResponseHandler(error as AxiosError)) as unknown as CustomError;
		}
	}
);

export const IrradianceSlice = createSlice({
	name: 'irradiance',
	initialState,
	reducers: {
		setIrrandiance(state, action: PayloadAction<Partial<IrradianceState>>) {
			let {selectedMonth, showPanels}= state.data;
			if (action.payload.showIrradiance) {
				selectedMonth = initialState.data.selectedMonth;
				showPanels = true;
			}
			state.data = { ...state.data, selectedMonth, showPanels, ...action.payload };
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchRoofData.fulfilled, (state) => {
				state.data = {
					...initialState.data,
				};
			})
			.addCase(fetchIrradianceImages.pending, (state) => {
				state.data.showProgress = true;
			})
			.addCase(fetchIrradianceImages.fulfilled, (state, action) => {
				state.data = {
					...state.data,
					showProgress: false,
					irradianceImages: action.payload
				};
			})
			.addCase(fetchIrradianceImages.rejected, (state) => {
				state.data = {
					...initialState.data,
				};
			}).addCase(changePanel.pending, (state, action) => {
				const { switchingSource } = action.meta.arg;
				if(switchingSource) state.data.showIrradiance= false;
			});
	},
});

export const { setIrrandiance } = IrradianceSlice.actions;

export default IrradianceSlice.reducer;
