import React, { InputHTMLAttributes, forwardRef } from 'react';
import { Switch, Slider } from './style';
import { Interpolation } from 'styled-components';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	styles?: Interpolation<React.CSSProperties>;
}

const CustomToggle = forwardRef<HTMLInputElement, Props>((props, ref) => {
	const {styles, ...restInputProps} = props;
	return (
		<>
			<Switch styles={styles}>
				<input ref={ref} type="checkbox" {...restInputProps}/>
				<Slider/>
			</Switch>
		</>
	);
});

CustomToggle.displayName = 'CustomToggle';
export default CustomToggle;