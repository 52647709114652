import { EnergyState } from 'store/slices/EnergySlice/types';
import { PanelTitle } from '../style';

interface Props {
	energy: EnergyState;
}

export default function Offset({ energy }: Props) {
	const offset = Math.round((energy.deratedEnergy / energy.billEnergy) * 100);
	return <PanelTitle>
		<h4>Offset</h4>
		<h2 className="primary-color">{`${offset}%`}</h2>
	</PanelTitle>;
}
