import Button from 'components/Basic/Button';
import { deleteAllFacetsFromPanelDrawer, disableDraggingInKonvaGroups, getAllKonvaGroups } from 'components/tool/util';
import Konva from 'konva';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { changePanel } from 'store/slices/RoofDataSlice/roofDataSlice';
import { setRoofIndexes, setEditState, setDeletedRoofIndexes } from 'store/slices/ToolSlice';
import { createNewFacets, translatePanels } from '../api';
import { useRefs } from 'contexts/RefContext';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { getAbsolutePointsOfTranslatedPanels } from '../../util';
import { useKonvaScale } from 'hooks/useKonvaScale';

export default function CalculateButton() {
	const { roofIndexes, drawModeEnabled, deletedRoofIndexes } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { isLoading: isLoadingChangeOrientation } = useSelector((state: RootState) => state.changeOrientationModal.data);
	const { uuid, allRoofSegs, panel, imagerySource } = useSelector((state: RootState) => state.roofData.data);
	const dispatch = useDispatch<AppDispatch>();
	const { konvaRef } = useRefs();
	const toggleRef = useRef<HTMLInputElement>(null);
	const isRemoveShadingqueryRunning = useQueriesRunningInBackGround(['removeShading']);
	const scale= useKonvaScale();

	function disbaleEditMode() {
		dispatch(setRoofIndexes({ roofIndexes: [] }));
		dispatch(setEditState({ enabled: false }));
		if (konvaRef?.current) {
			(konvaRef.current?.findOne('Transformer') as Konva.Transformer).setNodes([]);
			disableDraggingInKonvaGroups(getAllKonvaGroups(konvaRef?.current));
		}
	}

	function enableEditMode() {
		dispatch(setEditState({ enabled: true }));
		if (toggleRef.current) toggleRef.current.checked = true;
	}

	function deleteFacet() {
		if (!konvaRef?.current) return;
		deleteAllFacetsFromPanelDrawer(konvaRef.current);
	}

	const translatePanelPostionsQuery = useQuery(
		'translate-panels',
		async () => {
			if(!konvaRef?.current) return ;
			const newTranslatedPostions = getAbsolutePointsOfTranslatedPanels(konvaRef.current, roofIndexes, allRoofSegs, scale);
			if (!newTranslatedPostions.length) throw new Error('E_NO_TRANSLATIONS_TO_SAVE');
			await translatePanels(newTranslatedPostions, panel.key, uuid);
		},
		{
			onSuccess: async () => {
				disbaleEditMode();
				await dispatch(changePanel({ uuid, panelKey: panel.key, source: imagerySource }));
				if (toggleRef.current) toggleRef.current.checked = false;
			},
			onError: (error: Error) => {
				if (error.message === 'E_NO_TRANSLATIONS_TO_SAVE') return;
				if(error.message === 'E_WHILE_GETTING_TRANSLATION_FOR_SEGMENT') {
					disbaleEditMode();
					return;
				}
				enableEditMode();
				toast.error('something went wrong, please try again');
			},
			retry: (failureCount, error: Error) => {
				return error.message !== 'E_NO_TRANSLATIONS_TO_SAVE' && failureCount < 2;
			},
			enabled: false,
		}
	);

	const createNewFacetsQuery = useQuery(
		'removeDeletedFacets',
		async () => {
			return await createNewFacets({
				newPanelFacets: [],
				deletedRoofSegs: deletedRoofIndexes,
			}, panel.key, uuid);
		},
		{
			onSuccess: async () => {
				await dispatch(changePanel({ uuid, panelKey: panel.key, source: imagerySource }));
				setDeletedRoofIndexes({deletedRoofIndexes:[]});
				deleteFacet();
			},
			onError: (error: Error) => {
				if (error.message === 'E_NO_TRANSLATIONS_TO_SAVE') return;
			},
			retry: 0,
			enabled: false,
		}
	);

	if(drawModeEnabled){
		return null;
	}

	return (
		<Button
			style={{
				color: '#4A525A',
				fontSize: '15px',
				background: '#feb517',
				borderColor: '#feb517',
				boxShadow: '0px 2.25911px 4px rgba(100, 100, 100, 0.12)',
				borderRadius: '91.4939px'
			}}
			onClick={async() => {
				if(deletedRoofIndexes.length){
					await createNewFacetsQuery.refetch();
				}
				translatePanelPostionsQuery.refetch();
			}}
			disabled={translatePanelPostionsQuery.isFetching || isLoadingChangeOrientation}
			showLoader={translatePanelPostionsQuery.isFetching || isLoadingChangeOrientation || isRemoveShadingqueryRunning}
			className='CalculateBtn'>
			{/* {(!translatePanelPostionsQuery.isFetching || !isLoadingChangeOrientation) && */}
			<svg style={{ width: '15px', marginRight: '.5px' }} width="15" height="19"
				viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M13.1875 1H1.8125C1.36377 1 1 1.36377 1 1.8125V16.4375C1 16.8862 1.36377 17.25 1.8125 17.25H13.1875C13.6362 
				17.25 14 16.8862 14 16.4375V1.8125C14 1.36377 
				13.6362 1 13.1875 1Z" stroke="white" strokeWidth="1.625" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M13.1875 1H1.8125C1.36377 1 1 1.36377 1 1.8125V16.4375C1 16.8862 1.36377 17.25 1.8125 17.25H13.1875C13.6362 
				17.25 14 16.8862 14 16.4375V1.8125C14 1.36377 
				13.6362 1 13.1875 1Z" stroke="#28373E" strokeWidth="1.625" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M3.4375 3.84375H11.5625V7.5H3.4375V3.84375Z"
					stroke="white" strokeWidth="1.625" strokeLinecap="round" strokeLinejoin="round" />
				<path d="M3.4375 3.84375H11.5625V7.5H3.4375V3.84375Z" stroke="#28373E" strokeWidth="1.625" strokeLinecap="round"
					strokeLinejoin="round" />
				<path fillRule="evenodd" clipRule="evenodd" d="M4.65625 10.75C5.10498 10.75 5.46875 10.3862 
				5.46875 9.9375C5.46875 9.48877 5.10498 9.125 4.65625 9.125C4.20752 9.125 3.84375 9.48877
				 3.84375 9.9375C3.84375 10.3862 4.20752 10.75 4.65625 10.75ZM4.65625 13.1875C5.10498 13.1875
				 5.46875 12.8237 5.46875 12.375C5.46875 11.9263 5.10498 11.5625 
				 4.65625 11.5625C4.20752 11.5625 3.84375 11.9263 3.84375 12.375C3.84375 
				 12.8237 4.20752 13.1875 4.65625 13.1875ZM5.46875 14.8125C5.46875 
				 15.2612 5.10498 15.625 4.65625 15.625C4.20752 15.625
					3.84375 15.2612 3.84375 14.8125C3.84375 14.3638 4.20752 14 
					4.65625 14C5.10498 14 5.46875 14.3638 5.46875 14.8125ZM7.5 
					10.75C7.94873 10.75 8.3125 10.3862 8.3125 9.9375C8.3125 9.48877
					 7.94873 9.125 7.5 9.125C7.05127 9.125 6.6875 9.48877 6.6875 
					 9.9375C6.6875 10.3862 7.05127 10.75 7.5 10.75ZM8.3125 12.375C8.3125 
					 12.8237 7.94873 13.1875 7.5 13.1875C7.05127 13.1875 6.6875 12.8237 6.6875 12.375C6.6875 11.9263 
					 7.05127 11.5625 7.5 11.5625C7.94873 11.5625 8.3125 11.9263 8.3125 
					 12.375ZM7.5 15.625C7.94873 15.625 8.3125 15.2612 8.3125 
					 14.8125C8.3125 14.3638 7.94873 14 7.5 14C7.05127 14 6.6875 14.3638 6.6875 14.8125C6.6875 15.2612 7.05127 
					 15.625 7.5 15.625ZM11.1562 9.9375C11.1562 10.3862 10.7925 10.75 
					 10.3438 10.75C9.89502 10.75 9.53125 10.3862 9.53125 9.9375C9.53125 
					 9.48877 9.89502 9.125 10.3438 9.125C10.7925 9.125 11.1562 9.48877 11.1562 9.9375ZM10.3438 13.1875C10.7925 
					 13.1875 11.1562 12.8237 11.1562 12.375C11.1562 11.9263 10.7925 
					 11.5625 10.3438 11.5625C9.89502 11.5625 9.53125 11.9263 9.53125 
					 12.375C9.53125 12.8237 9.89502 13.1875 10.3438 13.1875ZM11.1562 14.8125C11.1562 15.2612 
					 10.7925 15.625 10.3438 15.625C9.89502 15.625 9.53125 15.2612 
					 9.53125 14.8125C9.53125 14.3638 9.89502 14 10.3438 14C10.7925 14 11.1562 14.3638 11.1562 14.8125Z" fill="white" />
				<path fillRule="evenodd" clipRule="evenodd" d="M4.65625 
				10.75C5.10498 10.75 5.46875 10.3862 5.46875 9.9375C5.46875 9.48877 5.10498 9.125 
				4.65625 9.125C4.20752 9.125 3.84375 9.48877 3.84375 
				9.9375C3.84375 10.3862 4.20752 10.75 4.65625 10.75ZM4.65625 
				13.1875C5.10498 13.1875 5.46875 12.8237 5.46875 12.375C5.46875 11.9263 5.10498 11.5625 4.65625 11.5625C4.20752 11.5625 3.84375 
				11.9263 3.84375 12.375C3.84375 12.8237 4.20752 13.1875 4.65625
				 13.1875ZM5.46875 14.8125C5.46875 15.2612 5.10498 15.625 4.65625 
				 15.625C4.20752 15.625 3.84375 15.2612 3.84375 14.8125C3.84375 14.3638 4.20752 14 4.65625 
				14C5.10498 14 5.46875 14.3638 5.46875 14.8125ZM7.5 10.75C7.94873 
				10.75 8.3125 10.3862 8.3125 9.9375C8.3125 9.48877 7.94873 9.125 7.5 9.125C7.05127 9.125 6.6875 9.48877 6.6875 9.9375C6.6875 
				10.3862 7.05127 10.75 7.5 10.75ZM8.3125 12.375C8.3125 12.8237 
				7.94873 13.1875 7.5 13.1875C7.05127 13.1875 6.6875 12.8237 6.6875 
				12.375C6.6875 11.9263 7.05127 11.5625 7.5 11.5625C7.94873 11.5625
				 8.3125 11.9263 8.3125 12.375ZM7.5 15.625C7.94873 15.625 8.3125 
				 15.2612 8.3125 14.8125C8.3125 14.3638 7.94873 14 7.5 14C7.05127 14 6.6875 14.3638 6.6875 14.8125C6.6875 
				 15.2612 7.05127 15.625 7.5 15.625ZM11.1562 9.9375C11.1562 10.3862 
				 10.7925 10.75 10.3438 10.75C9.89502 10.75 9.53125 10.3862 9.53125 
				 9.9375C9.53125 9.48877 9.89502 9.125 10.3438 9.125C10.7925 9.125 
				 11.1562 9.48877 11.1562 9.9375ZM10.3438 13.1875C10.7925 13.1875 
				 11.1562 12.8237 11.1562 12.375C11.1562 11.9263 10.7925 11.5625 
				 10.3438 11.5625C9.89502 11.5625 9.53125 11.9263 9.53125 12.375C9.53125 
				 12.8237 9.89502 13.1875 10.3438 13.1875ZM11.1562 14.8125C11.1562 
				 15.2612 10.7925 15.625 10.3438 15.625C9.89502 15.625 9.53125 15.2612 
				 9.53125 14.8125C9.53125 14.3638 9.89502 14 10.3438 14C10.7925 14 11.1562 
				 14.3638 11.1562 14.8125Z" fill="#28373E" />
			</svg>

			{/* } */}
			{translatePanelPostionsQuery.isFetching || isLoadingChangeOrientation || isRemoveShadingqueryRunning ? 'Calculating' : 'Calculate'}
		</Button>
	);
}