import { useEffect, useState } from 'react';

const data = [
	'Your solar system is being masterfully crafted by our advanced AI.',
	'Choose ideal solar panels & batteries tailored to suit your specific needs.',
	'Get credit approved in secs - select from loan or cash options.',
	'Embark on a journey to significant financial savings!',
];
const LoadingSlider = () => {
	const [step, setStep] = useState(1);
	useEffect(() => {
		let count = 1;
		const intRef = setInterval(() => {
			if (count === data.length) {
				setStep(1);
				count = 1;
				return;
			}
			setStep((prev) => prev + 1);
			count++;
		}, 1500);

		return () => {
			clearInterval(intRef);
		};
	}, []);

	const slideStyle = {
		transform: `translateX(-${(step - 1) * 100}%)`,
	};
	return (
		<div>
			<h3 className="silder-heading">
        Slash the Noise with Solar at Unbeatable Prices
			</h3>
			<div className="slider">
				<div className="slide-container">
					{data.map((content, i) => (
						<div
							key={i}
							className={'slide-content active' + `${i + 1 === step ? 'active' : ''}`}
							style={slideStyle}
						>
							{content}
						</div>
					))}
				</div>
				<div className="slider-dots">
					{data.map((content, i) => (
						<span
							key={i}
							className={'dot ' + `${i + 1 === step ? 'active' : ''}`}
						></span>
					))}
				</div>
			</div>
		</div>
	);
};
export default LoadingSlider;
