
import { RefObject, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import * as THREE from 'three';
import { OrbitControls } from '@three-ts/orbit-controls';
import { fromArrayBuffer } from 'geotiff';
import { useRefs } from 'contexts/RefContext';
import { changeVisibilityOfPanelsAndGetCanvasImageForThreeMode } from 'components/DisplayEnergy/util';

type Props = {
	setThree3ModelReady: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function ThreeDImage(props: Props) {
	const { setThree3ModelReady } = props;
	const { data: { dsmUrl } } = useSelector((state: RootState) => state.roofData);
	const { fullScreenEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);

	const canvasRef = useRef<HTMLDivElement>(null) as RefObject<HTMLDivElement>;
	const { konvaRef } = useRefs();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async function createGeometryFromDSMData(data: any, rgbWidth: any, rgbHeight: any) {
		const scaleFactor = 7;
		const tiff = await fromArrayBuffer(data);
		const dsmImage = await tiff.getImage();
		const dsmData = await dsmImage.readRasters();
		const dsmWidth = dsmImage.getWidth();
		const dsmHeight = dsmImage.getHeight();
		const dsmDataArray = dsmData[0] as THREE.TypedArray;

		const geometry = new THREE.BufferGeometry();

		const vertices = new Float32Array(dsmDataArray.length * 3);
		for (let i = 0; i < dsmDataArray.length; i++) {
			vertices[i * 3] = i % dsmImage.getWidth();
			vertices[i * 3 + 1] = Math.floor(i / dsmImage.getWidth());
			vertices[i * 3 + 2] = dsmDataArray[i] * scaleFactor;
		}
		const positionAttribute = new THREE.BufferAttribute(vertices, 3);
		geometry.setAttribute('position', positionAttribute);

		const indices = [];
		const width = dsmImage.getWidth();
		const height = dsmImage.getHeight();
		for (let i = 0; i < height - 1; i++) {
			for (let j = 0; j < width - 1; j++) {
				const a = i * width + j;
				const b = a + width;
				const c = b + 1;
				const d = a + 1;
				indices.push(a, d, b);
				indices.push(b, d, c);
			}
		}
		geometry.setIndex(indices);
		const widthRatio = dsmWidth / rgbWidth;
		const heightRatio = dsmHeight / rgbHeight;

		//let dsmImageWidth = dsmWidth;

		const uvs = [];
		for (let i = 0; i < geometry.attributes.position.count; i++) {
			const x = (geometry.attributes.position.getX(i) / widthRatio) / rgbWidth;
			const y = 1 - ((geometry.attributes.position.getY(i) / heightRatio) / rgbHeight); // Flip the y coordinate
			uvs.push(x, y);
		}
		geometry.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));

		return geometry;
	}

	const getImageData = () => {
		if (konvaRef && konvaRef.current) {
			const img = changeVisibilityOfPanelsAndGetCanvasImageForThreeMode(konvaRef.current, fullScreenEnabled);
			return img;
		}
	};

	useEffect(() => {

		const jpgImg = getImageData() as string;

		const scene = new THREE.Scene();
		const camera = new THREE.PerspectiveCamera(40, window.innerWidth / window.innerHeight, 0.1, 1000);
		camera.far = 10000;
		const renderer = new THREE.WebGLRenderer();
		renderer.setClearColor(0xffffff);
		renderer.toneMapping = THREE.ACESFilmicToneMapping;
		renderer.outputColorSpace = THREE.SRGBColorSpace;
		scene.scale.y = -1;
		const rect = canvasRef.current?.getBoundingClientRect() as DOMRect;

		renderer.setSize(rect.width, rect.height);
		if (canvasRef.current)
			canvasRef.current.appendChild(renderer.domElement);

		// Add controls
		const controls = new OrbitControls(camera, renderer.domElement);

		// Load RGB image as texture
		const textureLoader = new THREE.TextureLoader();
		textureLoader.load(jpgImg, function (texture) {
			texture.colorSpace = THREE.SRGBColorSpace;
			// Load DSM data as geometry
			const geometryLoader = new THREE.FileLoader();
			geometryLoader.setResponseType('arraybuffer');
			geometryLoader.load(dsmUrl as string, async function (data) {
				// Get the width and height of the RGB image
				const rgbWidth = texture.image.width;
				const rgbHeight = texture.image.height;
				const geometry = await createGeometryFromDSMData(data, rgbWidth, rgbHeight); // You'll need to implement this function

				const material = new THREE.MeshPhongMaterial({
					map: texture,
				});

				const ambientLight = new THREE.AmbientLight(0xffffff, 2); // Soft white light
				scene.add(ambientLight);
				const directionalLight = new THREE.DirectionalLight(0xffffff, 1); scene.add(directionalLight);
				const mesh = new THREE.Mesh(geometry, material);

				mesh.rotation.x = Math.PI / 4 - 0.6;
				scene.add(mesh);

				geometry.computeBoundingBox();

				geometry.computeVertexNormals();

				const center = geometry.boundingBox ? geometry.boundingBox.getCenter(new THREE.Vector3()) : new THREE.Vector3();

				const minZ = geometry.boundingBox ? geometry.boundingBox.min.z : 0;

				geometry.translate(-center.x, -center.y, -(minZ - 1));

				let distance = 10;
				if (geometry.boundingBox)
					distance = Math.max(geometry.boundingBox.max.x, geometry.boundingBox.max.y, geometry.boundingBox.max.z) * 1.5;
				camera.position.set(0, -distance, distance);
				camera.lookAt(0, 0, 0);
				controls.target.set(0, 0, 0);
				controls.saveState();
				const textureAspectRatio = rgbWidth / rgbHeight;

				const backgroundTexture = textureLoader.load('bg3dsq.png');
				scene.background = backgroundTexture;
				backgroundTexture.colorSpace = THREE.SRGBColorSpace;
				camera.aspect = textureAspectRatio;
				camera.updateProjectionMatrix();
				controls.enableRotate = false;

				controls.update();

				setThree3ModelReady(true);
				const animate = () => {
					requestAnimationFrame(animate);

					renderer.render(scene, camera);
				};
				animate();

				let mouseDown = false;
				let mouseX = 0;
				let mouseY = 0;
				if (!canvasRef.current)
					return;
				canvasRef.current.addEventListener('pointerdown', (event) => {
					mouseDown = true;
					mouseX = event.clientX;
					mouseY = event.clientY;
				});

				window.addEventListener('pointerup', () => {
					mouseDown = false;
				});

				window.addEventListener('pointermove', (event) => {
					if (mouseDown) {
						const deltaX = event.clientX - mouseX;
						const deltaY = event.clientY - mouseY;

						mesh.rotation.z -= deltaX * 0.01;
						mesh.rotation.x -= deltaY * 0.01;

						const maxRotationX = Math.PI / 4 - 0.05; // Adjust this value as needed
						const minRotationX = -Math.PI / 4; // Adjust this value as needed

						mesh.rotation.x = Math.max(mesh.rotation.x, minRotationX);
						mesh.rotation.x = Math.min(mesh.rotation.x, maxRotationX);

						mouseX = event.clientX;
						mouseY = event.clientY;
					}
				});

			}, undefined, function (error) {
				console.error('An error occurred while loading the DSM data:', error);
			});
		}, undefined, function (error) {
			console.error('An error occurred while loading the texture:', error);
		});
	}, []);

	return (
		<div style={{ width: '100%', height: '100%' }} ref={canvasRef}></div>
	);
}