import { useIsFetching, useIsMutating, useQueryClient } from 'react-query';

const useQueriesRunningInBackGround = (keys?:string[]) => {
	const isFetching = useIsFetching();
	const isMutating = useIsMutating();

	// Combine the results of useIsFetching and useIsMutating
	let queriesRunningInBackGround = !!isFetching || !!isMutating;

	if(keys?.length){
		// Get all queries with the specified key
		const queryClient = useQueryClient();
		queriesRunningInBackGround = (keys.some(key => queryClient.getQueryState(key)?.isFetching));
	}

	return queriesRunningInBackGround;
};

export default useQueriesRunningInBackGround;