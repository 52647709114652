import { getPanelDrawerGroup } from 'components/tool/util';
import { useRefs } from 'contexts/RefContext';
import Konva from 'konva';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export function useKonvaScale() {
	const { konvaRef } = useRefs();
	const { imgHeight, imgWidth } = useSelector((state: RootState) => state.roofData.data);

	const scale = useMemo(() => {
		let x = 1, y = 1;
		if (konvaRef?.current) {
			const stage = konvaRef?.current;
			const raster= getPanelDrawerGroup(stage)?.find('Image')[0] as Konva.Image;
			if(raster){
				x = (raster.width() / imgWidth);
				y = (raster.height() / imgHeight);
			}
			
		}
		return { x, y };
	}, [konvaRef?.current?.height(), konvaRef?.current?.width()]);

	return scale;
}
