import axios from 'axios';
import { getToken, createApiUrl } from '../../../../util';
import { NewlyCreatedFacetHullCoords } from 'store/slices/ToolSlice/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function translatePanels(translatePos: any, panelKey: string, uuid: string) {
	const token = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	};

	const body = {
		translatePositions:translatePos,
		uuid,
		panelKey
	};

	const apiUrl = '/designs/translate-panels-to-new-position';
	const { data } = await axios.post(createApiUrl(apiUrl), body, config);
	return data as RawSolarQueryData;
}

async function createNewFacets(newFacets: { newPanelFacets: NewlyCreatedFacetHullCoords[] , deletedRoofSegs: string[]}, 
	panelKey: string, uuid: string) {
	const token = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	};

	const body = {
		panelFacets: newFacets.newPanelFacets,
		deletedRoofSegs: newFacets.deletedRoofSegs,
		uuid,
		panelKey,
	};

	const apiUrl = '/designs/edit-roof-segments';
	const { data } = await axios.post(createApiUrl(apiUrl), body, config);
	return data as RawSolarQueryData;
}

async function removeShading(uuid: string ,payload:{panelKey:string; facetId: string; isUserMapped: boolean}) {
	const token = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	};
	const apiUrl = `/designs/remove-roof-segment-shading/${uuid}?panelKey=${payload.panelKey}&facetId=${payload.facetId}&isUserMapped=${payload.isUserMapped}`;
	return await axios.post(createApiUrl(apiUrl), null, config);
}

async function undoRemoveShading(uuid: string ,payload:{panelKey:string; facetId: string; isUserMapped: boolean}) {
	const token = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${token}`
		}
	};
	const apiUrl = `/designs/undo-remove-roof-segment-shading/${uuid}?panelKey=${payload.panelKey}&facetId=${payload.facetId}&isUserMapped=${payload.isUserMapped}`;
	return await axios.post(createApiUrl(apiUrl), null, config);
}

export {
	translatePanels,
	createNewFacets,
	removeShading,
	undoRemoveShading
};