import { IRRADIANCE_UPRATE_RATE } from '../../../constants';
import { getDeratedValue, getPercentage } from '../../../util';
import { EnergyState } from './types';

export function updateEnergyBySlider(
	updatedPanelCount: number,
	rasterSolarPanels: RasterSolarPanel[],
	deratedBy: number,
) {
	let energy = 0, irradiance = 0;
	const selectedPanels = [];
	for (let i = 0; i < updatedPanelCount; i++) {
		energy += rasterSolarPanels[i].yearlyEnergyDcKwh;
		irradiance += rasterSolarPanels[i].irradiance;
		selectedPanels.push(rasterSolarPanels[i].id);
	}
	return {
		energy,
		deratedEnergy: getDeratedValue(energy, deratedBy),
		totalNoOfPanelsSelectedByUser: selectedPanels.length,
		selectedPanels,
		irradiance,
		upratedIrradiance: irradiance + getPercentage(irradiance, IRRADIANCE_UPRATE_RATE)
	};
}

export function updateEnergyByPanels(
	selectedPanels: string[],
	values: {energy: number, irradiance: number},
	panelInfo: { energyBoost: number; panelId: string, irradianceBoost: number},
	deratedBy: number
) {
	const { energyBoost, panelId, irradianceBoost } = panelInfo;
	const pnlInArray = selectedPanels.includes(panelId);
	const addPanelAction = energyBoost > 0;
	if (addPanelAction) !pnlInArray && selectedPanels.push(panelId);
	else if (pnlInArray)
		selectedPanels = selectedPanels.filter((id) => id !== panelId);

	return {
		energy: values.energy + energyBoost,
		deratedEnergy: getDeratedValue(values.energy + energyBoost, deratedBy),
		irradiance: values.irradiance + irradianceBoost,
		upratedIrradiance: (values.irradiance + irradianceBoost) + getPercentage(values.irradiance + irradianceBoost, IRRADIANCE_UPRATE_RATE),
		totalNoOfPanelsSelectedByUser: selectedPanels.length,
		selectedPanels,
	};
}

export function getUpdatedEnergyDataToSendToParent(energyState: EnergyState) {
	return {
		systemSize:
      (energyState.totalNoOfPanelsSelectedByUser *
        energyState.panelCapacityWatts) /
      1000,
		energy: energyState.energy,
		totalPanels: energyState.totalNoOfPanelsSelectedByUser,
		deratedEnergy: energyState.deratedEnergy,
		deratedBy: energyState.deratedBy,
		irradiance: energyState.irradiance,
		upratedIrradiance: energyState.upratedIrradiance
	};
}
