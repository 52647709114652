import { useRoutes } from 'react-router-dom';
import ROUTES from '../constants/routes';
import LoadRoofDesign from 'pages/LoadRoofDesign/LoadRoofDesign';
import LocationMap from 'pages/LocationMap/LocationMap';
import Home from 'pages/Home';
import PrivateRoute from './PrivateRoute';

// todo make use of lazy components
// and seperate provate routes from public routes

// wrap private compoents like and check if you need to pass props too
{/* <PRivate></PRivate> */ }
export default function AppRoutes() {
	return useRoutes([
		{
			path: ROUTES.HOME,
			element: <Home />,
		},
		{
			path: ROUTES.SHOW_DATA,
			element: <PrivateRoute><LoadRoofDesign /></PrivateRoute>
		},
		{
			path: ROUTES.SHOW_MAP,
			element: <PrivateRoute><LocationMap /></PrivateRoute>
		}
	]);
}