import { useState, useEffect } from 'react';

export default function useScreenDims() {
	const [screenDims, setScreenDims] = useState<Dimensions>(
		{
			height: window.innerHeight,
			width: window.innerWidth
		}
	);

	useEffect(() => {
		const handleResize = () => {
			setScreenDims({
				height: window.innerHeight,
				width: window.innerWidth
			});
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return screenDims;
}
