import Button from 'components/Basic/Button';
import { useRefs } from 'contexts/RefContext';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { batchUpdateToolState } from 'store/slices/ToolSlice';
import { DEFAULT_KONVA_SCALE, DEFAULT_KONVA_STAGE_POSITION, PARENT_EVENTS } from '../../../../constants';
import { useEffect } from 'react';

export default function FullViewBtn() {

	const { fullScreenEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const dispatch = useDispatch();

	useEffect(() => {
		function handleSaveDesignEvent(event: MessageEvent) {
			if (event.data === 'DISABLE_FULL_SCREEN_MODE') {
				dispatch(batchUpdateToolState({
					fullScreenEnabled: false, zoomLevel: DEFAULT_KONVA_SCALE, konvaStagePosition: DEFAULT_KONVA_STAGE_POSITION
				}));
			}
		}
		window.addEventListener('message', handleSaveDesignEvent);
		return () => {
			window.removeEventListener('message', handleSaveDesignEvent);
		};
	});
	
	function toggleFullScreen(enabled= fullScreenEnabled) {
		dispatch(batchUpdateToolState({ fullScreenEnabled: !enabled, zoomLevel: DEFAULT_KONVA_SCALE }));
		window.parent.postMessage(
			{ id: 'FULL_VIEW_MODE', detail: { fullScreenEnabled: !enabled } },
			'*'
		);
	}
	return (
		<Button 
			onClick={()=>toggleFullScreen()}
			style={{
				borderRadius: '100%',
				background: `${fullScreenEnabled ? 'var(--secondary)' : '#fff'}`,
				borderColor: `${fullScreenEnabled ? 'var(--secondary)' : '#C7CACF'}`,
				color: '#64686A',
				padding:'10px 10px'
			}}
			className="fullscreenviewVisible"
		>
			{!fullScreenEnabled && <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 4.75V2.5C1 2.10218 1.15804 1.72064 1.43934 1.43934C1.72064 1.15804 2.10218 1 2.5 
					1H4.75M13 9.25V11.5C13 11.8978 12.842 12.2794 12.5607 12.5607C12.2794 12.842 11.8978 13 11.5 
					13H9.25M9.25 1H11.5C11.8978 1 12.2794 1.15804 12.5607 1.43934C12.842 1.72064 13 2.10218 13 
					2.5V4.75M4.75 13H2.5C2.10218 13 1.72064 12.842 1.43934 12.5607C1.15804 12.2794 1 11.8978 
					1 11.5V9.25" stroke={`${fullScreenEnabled ? '#fff' : '#64686A'}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>}
			{fullScreenEnabled && <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 9.25L3.25 9.25C3.64782 9.25 4.02936 9.40804 4.31066 
9.68934C4.59196 9.97065 4.75 10.3522 4.75 10.75L4.75 13" stroke={`${fullScreenEnabled ? '#fff' : '#64686A'}`}
				strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M9.25 13L9.25 10.75C9.25 10.3522 9.40804 9.97064 9.68934 9.68934C9.97065 
9.40804 10.3522 9.25 10.75 9.25L13 9.25" stroke={`${fullScreenEnabled ? '#fff' : '#64686A'}`} strokeWidth="1.5" 
				strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M13 4.75L10.75 4.75C10.3522 4.75 9.97065 4.59196 9.68934 4.31066C9.40804 4.02936 9.25 3.64782 9.25 3.25L9.25 1" 
					stroke={`${fullScreenEnabled ? '#fff' : '#64686A'}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M4.75 1L4.75 3.25C4.75 3.64782 4.59196 4.02936 4.31066 4.31066C4.02936
				 4.59196 3.64782 4.75 3.25 4.75L1 4.75" stroke={`${fullScreenEnabled ? '#fff' : '#64686A'}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>}

		</Button>
	);

}