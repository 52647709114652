/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Button from '../../components/Basic/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { createNewDesign } from './api';
import Loader from '../../components/Loader';
import ROUTES from '../../constants/routes';
import { Mapwrap, MapHeader } from './styles';
import { PARENT_EVENTS, SOlAR_QOUTE_APP_NAME, SUBHUB_APP_NAME } from '../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'store';
import { fetchRoofData } from 'store/slices/RoofDataSlice/roofDataSlice';
import { removeFalsyValueFromObj } from '../../util';
import { convertObjValsToNum } from '../../util';
import { setQueryParamState } from 'store/slices/QueryParamsSlice';
import { AxiosError } from 'axios';

const containerStyle = {
	width: '100%',
	height: '100%',
};

function LocationMap() {
	const navigate = useNavigate();
	const data: any = useLocation();
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyDf2acZiqNzq9ZJiwVAm0k5xAvIW-QLiEM',
	});

	const [map, setMap] = useState<google.maps.Map>();
	const [center] = useState(data?.state?.coordinates);
	const queryParams = useSelector((state: RootState) => state.QueryParams.data);
	const dispatch = useDispatch<AppDispatch>();
	const [markerPosition, setMarkerPosition] = useState(center);
	const { org } = useSelector((state: RootState) => state.roofData.data);
	const size = 10;
	const plusSymbol = {
		path: `M 0,-${size} 0,${size} M -${size},0 ${size},0`,
		strokeColor: 'black',
		fillOpacity: 2,
		scale: 2
	};

	useEffect(() => {
		if (map) {
			const listener = map.addListener('center_changed', () => {
				setMarkerPosition({
					lat: map.getCenter()?.lat(),
					lng: map.getCenter()?.lng(),
				});
			});
			return () => {
				google.maps.event.removeListener(listener);
			};
		}
	}, [map]);

	useEffect(() => {
		function changeBillInputs(event: MessageEvent) {
			const iframeIsEmbeddedInSubhub = queryParams.parentAppName === SUBHUB_APP_NAME;
			// console.log('eventt', event);
			if (event.data?.eventType !== PARENT_EVENTS.ENERGY_INPUTS_CHANGED || !iframeIsEmbeddedInSubhub)
				return;
			const electricInputChanges = convertObjValsToNum(removeFalsyValueFromObj(event.data?.values));
			const { billAmount, energy } = electricInputChanges;
			if (!billAmount && !energy) return;
			dispatch(setQueryParamState({ ...queryParams, billAmount }));
		}
		window.addEventListener('message', changeBillInputs);
		return () => {
			window.removeEventListener('message', changeBillInputs);
		};
	}, []);

	const onLoad = React.useCallback(function callback(map: google.maps.Map) {
		const myWindow: any = window;
		const bounds = new myWindow.google.maps.LatLngBounds(
			data.state?.coordinates
		);
		map.fitBounds(bounds);

		setMap(map);
	}, [center]);

	// const latLngChange = (name:string, value:any) => {
	// 	console.log(name);
	// 	setCenter((prevCenter:any) => ({
	// 		...prevCenter,
	// 		[name]: parseFloat(value)
	// 	}));
	// };

	const onUnmount = React.useCallback(function callback() {
		setMap(undefined);
	}, []);

	const { billAmount, externalId, panelKey, parentAppName } = queryParams;
	const design = useQuery(
		['map', data.state?.coordinates],
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		() => createNewDesign(map?.getCenter()?.toUrlValue(), externalId!, +billAmount!, panelKey),
		{
			enabled: false,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
			retry: (failureCount: number, error: AxiosError<any>) => {
				// if (error.response?.data.error === 'E_INVALID_ADDRESS') {
				// 	window.parent.postMessage({ id: 'SOLARQUOTEEMBED_UNSUPPORTED_ADDRESS' }, '*');
				// 	return false;
				// }
				return !(failureCount > 2);
			},
			onSuccess: (fetchedData) => {

				window.parent.postMessage({ id: PARENT_EVENTS.SET_HOUSE, detail: { designUUID: fetchedData.designUUID } }, '*');
				dispatch(fetchRoofData({ uuid: fetchedData.designUUID as string, panelKey, source: fetchedData.source as ImagerySource }));
				navigate(ROUTES.SHOW_DATA);
			},
			onError: () => {
				window.parent.postMessage({ id: 'SOLARQUOTEEMBED_UNSUPPORTED_ADDRESS' }, '*');

			},
			cacheTime: 0,
		}
	);

	if (design.isLoading) {
		return <Loader
			loaderText='Building solar panel layout'
			slider={parentAppName === SOlAR_QOUTE_APP_NAME}
		/>;
	}

	// discusss baout it with Shaiabn  for all errors
	// if (design.isError) {
	// 	return <>
	// 		<p> unsupported address</p>
	// 		<Button onClick={
	// 			() => {
	// 				{
	// 					navigate(-1);
	// 				}
	// 			}}>
	// 			Back
	// 		</Button>
	// 	</>;
	// }

	return isLoaded ? (
		<Mapwrap>
			<MapHeader>
				<div className='left'>
					{parentAppName === SOlAR_QOUTE_APP_NAME && <h3 className='title'>Your Home Design</h3>}
				</div>
				<div className='right'>
					{/* <div>
						<LatitudeInput latitudeValue={map?.getCenter()?.lat()} setError={setError} onLatChange={latLngChange}/>
					</div>
					<div>
						<LongitudeInput longitudeValue={map?.getCenter()?.lng()} setError={setError} onLngChange={latLngChange}/>
					</div> */}
					{!org.setting?.isB2C && <span className='info'>Place the crosshairs over the center of the house in the map</span>}
					{org.setting?.isB2C && <span className='info'>Select your home and put in the center of the map</span>}
					<div className='btn-container'>
						<Button
							onClick={() => design.refetch()}
						>
							Run AI Design
						</Button>
					</div>
				</div>
			</MapHeader>
			{/* <SetBtnWrap>
				<h3>Set your house to the centre of map</h3>
				<Button primary
					onClick={() => design.refetch()}
				>
					Set House
				</Button>
			</SetBtnWrap> */}
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={center}
				zoom={19}
				options={{
					mapTypeId: google.maps.MapTypeId.HYBRID,
					disableDefaultUI: true,
					zoomControl: true,
					// zoom:20
				}}
				onLoad={onLoad}
				onUnmount={onUnmount}
			>
				{map && (
					<Marker
						position={markerPosition}
						icon={plusSymbol}
					/>
				)}
			</GoogleMap>

		</Mapwrap>
	) : (
		<></>
	);
}

export default React.memo(LocationMap);

