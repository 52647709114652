import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { OffsetModal } from './types';
import { 
	changePanel,
	fetchRoofData,
	suggestNewPanelAlignment,
	recalcuatedEnergyVals 
} from '../RoofDataSlice/roofDataSlice';

const initialState: { data: OffsetModal } = {
	data: {
		open: false,
		userHasGivenPermToExceedOffset: {
			offset120: false,
			offset150: false,
		}
	}
};

export const offsetModelSlice = createSlice({
	name: 'offsetModelProvider',
	initialState,
	reducers: {
		toggleOffsetState(state, action: PayloadAction<{ value: boolean }>) {
			state.data.open = action.payload.value;
		},
		updateUserPermission(state, action: PayloadAction<{ value: boolean, type: 'offset120' | 'offset150' }>) {
			state.data.userHasGivenPermToExceedOffset[action.payload.type] = action.payload.value;
		},
		setIntermediateEnergyState(state, action: PayloadAction<{value: OffsetModal['userHasGivenPermToExceedOffset']['value']}>) {
			state.data.userHasGivenPermToExceedOffset.value = action.payload.value;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchRoofData.fulfilled, (state, action) => {
				const offsetModel = {
					open: false,
					userHasGivenPermToExceedOffset: action.payload.userHasGivenPermToExceedOffset
				};
				state.data = offsetModel;
			}).
			addCase(suggestNewPanelAlignment.fulfilled, (state) => {
				state.data = initialState.data;
			}).
			addCase(changePanel.fulfilled, (state) => {
				state.data = initialState.data;
			}).addCase(recalcuatedEnergyVals.fulfilled, (state) => {
				state.data = initialState.data;
			});

	}
});

export const { toggleOffsetState, updateUserPermission, setIntermediateEnergyState } = offsetModelSlice.actions;

export default offsetModelSlice.reducer;