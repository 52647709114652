import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { setMoveStage } from 'store/slices/ToolSlice';

const queryClient = new QueryClient();

function App() {
	const dispatch = useDispatch<AppDispatch>();
	
	const handleKeyPress = (event:KeyboardEvent) => {
		if(event.key === 'Control'){
			dispatch(setMoveStage({shouldMoveStage: true}));
		}
	};
	
	const handleKeyUp = () => {
		dispatch(setMoveStage({shouldMoveStage: false}));
	};

	useEffect(() => {
		window.addEventListener('keydown', handleKeyPress);
		window.addEventListener('keyup', handleKeyUp);
		return () => {
			window.removeEventListener('keydown', handleKeyPress);
			window.removeEventListener('keyup', handleKeyUp);
		};
	}, []);
	return (
		<QueryClientProvider client={queryClient}>
			<Router>
				<div className='bodyWrapper'>
					<ToastContainer />
					<AppRoutes />
				</div>
			</Router>
		</QueryClientProvider>
	);
}

export default App;
