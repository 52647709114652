import styled, { } from 'styled-components';

export const SliderWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	gap: 1rem;

	@media screen and (max-width:540px) {
	&.EnableSliderWrapper{
		// flex-wrap: wrap;
		gap: 10px;
		align-items: flex-start;
		}
	
	}

		

	// @media (max-width: 480px) {
	// 	// flex-direction: column;
	// 	align-items: flex-start;
	// }
		
		@media (max-width: 410px) {
&.NonEnableSliderWrapper{
		flex-wrap: wrap;
		// align-items: center;
		flex-direction: column;
		align-items: start;
	}
		}

	h3 {
		color: #223343;
    font-weight: 400;
    font-size: 1rem;
    margin: 0 3px 0px 0px;
		white-space: nowrap;

		@media (max-width: 500px) {
			font-size: 1rem;
			margin-bottom:6px;
		}
	}
	.sliderWithName{
		display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	gap: 1rem;
	@media (max-width: 840px) {
	margin-left:30px}
		@media (max-width: 480px) {
	margin-left:0px}
	@media (max-width: 500px) {
		align-items: flex-start;
		width:100%;
		gap: 0.5rem;
	
	}
	}

	.mobile-offset{

	}
`;

export const RangeValueWrap = styled.div`
		max-width: 350px;
		width: 100%;
		@media (max-width: 840px) {
			max-width: 74%
		}
`;

export const RangeWrap = styled.div`
	display: flex;
	width:100%;
	flex-direction: row;
	align-items: center;
	@media (max-width: 935px) {
		justify-content: flex-start;
	}
		@media (max-width: 840px) {
		justify-content: flex-end;
	}
`;

export const RangePercentage = styled.div`
	display: flex;
	justify-content: flex-start;
	color: #999;
	padding-top: 2px;
	font-size: 15px;
	margin-right: 10px;
	min-width:35px;

	@media (max-width: 500px) {
	margin-right: 5px;
	}
`;

export const ToolHeaderWrap= styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;


	@media (max-width: 935px) {
		margin: 0 auto !important;
	}
`;

export const ToolHeaderMainWrap= styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
	@media (max-width: 980px) {
	&.NonView{

	width: 100%;
	 align-self: flex-end;
	   align-items: end;
        justify-content: end;
	.mobile-view{
			width:auto
		}
	}
		
		    
		width:100%;
		.mobile-view{
			// 
		}
			&.EnableModeView{
			flex-direction: column;
		align-items: start;
		.mobile-view{
			flex-direction: row;
			align-self: end;
			justify-content: end;
		}
			}
	}
			@media (max-width: 840px) {
		&.NonView {
        width: 100%;
				justify-content: space-between;
       
    }
		}
			@media (max-width: 380px) {
		&.NonView {
       flex-direction:column;
			 align-items:center;
			 .mobile-view {
       align-items:center;
			 align-self: center;
    }
       
    }
	@media (max-width: 760px) {
		// flex-direction: column;
		align-items: start;
		.mobile-view{
			width: auto;
			flex-direction: row;
			align-self: end;
		}
	}	
`;
export const StripButoonWrap= styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;

export const ToolZoomWrap= styled.div`
position:relative;
height: 100%;
width: 100%
`;
export const SliderWrapper= styled.div`
 display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
width: 100%;
@media (max-width: 840px) {
		flex-direction:column-reverse;
	}
&.NonViewWrapper{
	@media (max-width: 840px) {
	.NonEditViewWrapper{
	display :none
	}
	}

}
&.EnableModeViewWrapper{
@media (max-width: 1120px) {
		// flex-direction: column;
		.EnableModeView{
			flex-direction: row;
			align-self: end;
		}
	}
		@media (max-width: 840px) {
			flex-direction: column-reverse;
		.EnableModeView{
			// overflow:auto;
			// padding-bottom:5px
		}

		
	}
		@media (max-width: 480px) {
			.EnableModeView{
		flex-wrap: wrap;
        justify-content: center;}
		}

}
@media (max-width: 980px) {
		flex-wrap: wrap;
	}
`;
export const EditButtonLaptop= styled.div`
display: none ;
@media (min-width: 840px) {
	display: flex;
	}
`;
export const EditButtonMobile= styled.div`

display: flex;
@media (min-width: 840px) {
	display: none ;
	}
	@media (max-width: 480px) {
		// flex-direction:column;
		// gap:1rem
		}
`;
export const EditButtonWrap= styled.div`
	@media (max-width: 540px) {
	margin-top:5px}

`;

