import loader from './loader2.gif';
import { LoaderDiv } from './styles';
import LoadingSlider from './slider';
import { Interpolation } from 'styled-components';

type Props = {
  loaderText?: string;
	slider?: boolean;
	styles?: Interpolation<React.CSSProperties>;
};
export default function Loader(props: Props) {
	const { loaderText, slider, styles } = props;
	return (
		<LoaderDiv styles={styles}>
			<img src={loader} />
			{loaderText && <>{loaderText}...</>}
			{slider && <LoadingSlider />}
		</LoaderDiv>
	);
}

