import styled from 'styled-components';

const ButtonStyle = styled.button`
	background: var(--primary);
  appearance: none;
  border: 1px solid var(--primary);
	padding: 0.5em .72em;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
	position: relative; // Added for positioning the loader
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'Inter', sans-serif;
	gap:0.5rem;

  &:hover {
    opacity: .9;
  }

	&:disabled {
		cursor: not-allowed !important;
		opacity: 45%
	}


	.showLoader&:before {
		content: '';
		display:block;
		position:absolute;
		height:100%;
		width:100%;
		top:0;
		left:0;
		background-color:inherit;
		z-index:1;
		opacity: .45
	}
		
	.showLoader&:after {
		content: '';
		border: 4px solid red;
		border-color:  rgb(32 37 39 / 42%) rgb(253, 251, 247) #fdfbf7 !important;
		border-radius: 50%;
		border-top: 4px solid white;
		animation: spin 2s linear infinite;
		position: absolute;
		transform: translate(-50%, -50%);
		height: 50%;
		z-index: 10;
    aspect-ratio: 1/1;
		
	
		@-webkit-keyframes spin {
			0% {
				-webkit-transform: rotate(0deg);
			}
			100% {
				-webkit-transform: rotate(360deg);
			}
		}
	
		@keyframes spin {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
	}

`;

export { ButtonStyle };