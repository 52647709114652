import axios from 'axios';
import { createApiUrl, getToken } from '../../util';

async function createNewDesign(coord: string | undefined, externalID: string, billAmount: number, panelKey?: string) {
	const myToken = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${myToken}`
		}
	};

	if (!coord) return;
	const [lat, lng] = coord.split(',').map(eachVal => Number(eachVal));
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const requestBody: any = {
		latLng: {
			latitude: lat,
			longitude: lng
		},
		externalID,
		billAmount,
	};

	if (panelKey)
		requestBody.panelKey = panelKey;
	// console.log('line 22 config', config);
	const { data } = await axios.post(createApiUrl('/designs'), requestBody, config);

	return data;
}

type productionDataType = {
	systemSize: number,
	energy: number,
	offset: number,
	totalPanels: number
}

type DesignSaveObj = {
	selectedPanels: string[],
	productionData: productionDataType,
	billAmount: number,
	annualEnergy?: number,
	panelKey: string,
	source: string,
}

async function updateDesing(designSaveObj: DesignSaveObj, uuid: string) {

	const myToken = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${myToken}`
		}
	};

	await axios.patch(createApiUrl(`/designs/${uuid}`), designSaveObj, config);
}

async function getUnselectedPanels(uuid: string) {
	const myToken = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${myToken}`
		}
	};

	const { data } = await axios.get(createApiUrl(`/designs/${uuid}`), config);
	return data;
}

export {
	createNewDesign,
	updateDesing,
	getUnselectedPanels,
};
