import styled from 'styled-components';

export const FlexDivWrap = styled.div`
	display: flex;
  flex-direction: row;
  gap:1rem
`;

export const EditToolOptionsWrap = styled.div`
	display: flex;
	align-items: center; 
	// gap: 10px;
	margin-right: '10px';
`;
