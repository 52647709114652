import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type OrientationModal = {
	open: boolean
	isLoading: boolean
};

const initialState: { data: OrientationModal } = {
	data: {
		open: false,
		isLoading: false
	}
};

export const changeOrientationModalSlice = createSlice({
	name: 'changeOrientationModalProvider',
	initialState,
	reducers: {
		toggleOrientationModalState(state, action: PayloadAction<{ open: boolean, isLoading: boolean }>) {
			state.data.open = action.payload.open;
			state.data.isLoading = action.payload.isLoading;
		}
	}
});

export const { toggleOrientationModalState } = changeOrientationModalSlice.actions;

export default changeOrientationModalSlice.reducer;