import { getAbsolutePoints } from 'components/DisplayEnergy/util';
import Konva from 'konva';
import { getAllKonvaGroups, normalizeAngle } from '../util';

export function getAbsolutePointsOfTranslatedPanels(stage: Konva.Stage, roofIndexes: string[], 
	allRoofSegs: RasterRoofSegment[],currentScale: Vector2d
) {
	try{
		const groups = getAllKonvaGroups(stage)
			.filter(g => roofIndexes.includes(g.attrs.name.split('>')[2])) as Konva.Group[];
		const newPos: {
		segmentId: number,
		azimuth: number,
		previousAzimuthDegrees: number,
		pitch: number;
		translatedPos: {
			[id: string]: {
				pos: number[][]
			}
		}
	}[] = [];

		const stagePosition= stage.position();
		groups?.forEach(g => {
			const translatedPos: typeof newPos[0]['translatedPos'] = {};
			g.children?.forEach((child) => {
				if (child.className === 'Line') {
					const newPoints = (getAbsolutePoints(child as Konva.Line, currentScale, stagePosition) as number[]);
					translatedPos[child.attrs.id] = {
						pos: [
							[newPoints[0], newPoints[1]],
							[newPoints[2], newPoints[3]],
							[newPoints[4], newPoints[5]],
							[newPoints[6], newPoints[7]],
							[newPoints[8], newPoints[9]]]
					};
				}
			});
			const segmentId = g.attrs.name.split('>')[2];
			const roofSegment = allRoofSegs.find(rs=>rs.id === segmentId);
			if(!roofSegment) return [];
			newPos.push({
				segmentId,
				azimuth: normalizeAngle(g.rotation()),
				previousAzimuthDegrees: roofSegment.azimuthDegrees,
				translatedPos,
				pitch: roofSegment.pitchDegrees
			});

		});
		return newPos;
	}catch(e){
		throw new Error('E_WHILE_GETTING_TRANSLATION_FOR_SEGMENT');
	}
}

export function resetTraslateFacets(stage: Konva.Stage, roofIndexes: string[]) {
	const groups = getAllKonvaGroups(stage)
		.filter(g => roofIndexes.includes(g.attrs.name.split('>')[2])) as Konva.Group[];

	groups?.forEach(g => {
		g.setPosition({ x: 0, y: 0 });
	});
}