import styled, { Interpolation } from 'styled-components';

type props = {
  styles?: Interpolation<React.CSSProperties>
}

const LoaderDiv = styled.div<props>`
display: flex;
flex-direction:column;
width: 100%;
height: 100%;
align-items: center;
justify-content: center;	
// min-height: 100vh;
img{margin-top: 5px;}

${({ styles }) => styles}
`;

export {
	LoaderDiv,
};