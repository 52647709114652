import styled from 'styled-components';

export const SliderInput = styled.input`
	background: linear-gradient(to right, #ff932a 0%, rgb(255 147 42) 67.9245%, #a1a1a1 67.9245%, #a1a1a1 100%);
	width: 100%;
	height: 10px;
	border-radius: 16px;  
	outline: none;
	opacity: 0.7;
	-webkit-transition: .2s;
	transition: opacity .2s; 
	-webkit-appearance: none;
	 
	&::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 22px;
  border-radius: 50%; 
  background: #fff;
  cursor: pointer;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px;
}

	&::-moz-range-thumb {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #fff;
  cursor: e-resize;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px;
	background: #434343;
}

&[type="range"] {
	margin: 0 !important;
}

@media (max-width: 768px) {
	width: 90%;
}
	@media (max-width: 480px) {
	width: 100%;
}

`;

export const SliderWrap = styled.div`
	// background: #f2f2f2;
	padding: 0px 2px 3px 2px;
	border-radius: 16px;
	margin-bottom: 0px;
	position:relative;
	width: 100%;
	top: 2.5px;
	max-width: 420px;
	@media (max-width: 840px) {
	max-width: 100%;
}
`;

export const TotalPanel = styled.span` 
		color: #1457a5;
    font-weight: 500;
`;

