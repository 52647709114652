import axios from 'axios';
import { createApiUrl, getToken } from '../../util';
import { HourlyEngeryTOFAndSolarAccessData } from './types';

async function updateDesignBillAmount(uuid: string, billAmount: number) {
	const myToken = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${myToken}`
		}
	};

	const requestBody = {
		billAmount
	};

	const { data } = await axios.patch(createApiUrl(`/designs/update-design-bill-amount/${uuid}`), requestBody, config);
	return data;
}

async function updateDesignAnnualEnergy(uuid: string, annualEnergy: number) {
	const myToken = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${myToken}`
		}
	};

	const requestBody = {
		annualEnergy
	};

	const { data } = await axios.patch(createApiUrl(`/designs/update-design-annual-energy/${uuid}`), requestBody, config);
	return data;
}

async function updateDesign(uuid: string, showToggleForAllPanels: boolean){
	const myToken = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${myToken}`
		}
	};
	const requestBody = {
		showToggleForAllPanels
	};

	const { data } = await axios.patch(createApiUrl(`/designs/${uuid}`), requestBody, config);
	return data;
}

async function getPanelDetails() {
	const myToken = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${myToken}`
		}
	};

	const { data } = await axios.get<{panelDetails:PanelDetails[], panelFinancingInfo:panelFinancingInfo[]}>
	(createApiUrl('/organization/panel-details'), config);
	return data;
}

async function getMonthlyDetails(uuid: string,panelKey: string){
	const myToken = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${myToken}`
		}
	};

	const { data } = await axios.get(createApiUrl(`/designs/get-monthly-solar-details/${uuid}/?panelKey=${panelKey?panelKey:''}`), config);
	return data;
}

async function deleteMonthlyDetails(uuid: string, panelKey: string) {
	const myToken = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${myToken}`
		}
	};

	const { data } = await axios.delete(createApiUrl(`/designs/monthly-solar-details/${uuid}/?panelKey=${panelKey ? panelKey : ''}`), config);
	return data;

}

async function getFluxData(uuid: string){
	const myToken = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${myToken}`
		}
	};

	const { data } = await axios.get(createApiUrl(`/designs/get-flux-data/${uuid}`), config);
	return data;
}

async function getHourlyEnergy(uuid: string, panelKey: string) {
	const myToken = getToken('Authorization');
	const config = {
		headers: {
			'Authorization': `Bearer ${myToken}`
		}
	};

	const { data } = await axios.get(createApiUrl(`/designs/hourly-production/${uuid}/?panelKey=${panelKey ? panelKey : ''}`), config);
	return data as HourlyEngeryTOFAndSolarAccessData;
}

export {
	updateDesignBillAmount,
	updateDesign,
	getPanelDetails,
	getMonthlyDetails,
	updateDesignAnnualEnergy,
	deleteMonthlyDetails,
	getFluxData,
	getHourlyEnergy
};
