import { forwardRef } from 'react';
import { InputHTMLAttributes } from 'react';
import { InputStyle } from './style';
import { Interpolation } from 'styled-components';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	label?: string,
	error?: string,
	styles?: Interpolation<React.CSSProperties>;
}

const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { label, error, ...rest } = props;
	return (
		<>
			<InputStyle
				ref={ref}
				{...rest}
			/>
			{error &&
				<>
					<span style={{
						color: 'red',
						fontSize: '0.7rem',
						display: 'block',
						lineHeight: '1.5',
						marginTop: '0.4em'
					}}>
						{error}
					</span>
				</>}
		</>
	);
});

Input.displayName = 'Input';
export default Input;
