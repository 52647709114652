import axios from 'axios';
import { createApiUrl } from '../../util';

async function verifyPublicKey(publicKey: string) {
	const { data } = await axios.get(createApiUrl(`/organization/verify/${publicKey}`));
	return data;
}

async function getLatLng(address:string, publicKey: string) {
	const config = {
		headers: {
			'Authorization': `Bearer ${publicKey}`
		}
	};
	const { data } = await axios.get(createApiUrl(`/designs/getAddress/${address}`), config);
	return data;
}

async function verifyPanelKey(panelKey: string,publicKey: string) {
	// in this case because our backend will use dfault panel keys then
	const config = {
		headers: {
			'Authorization': `Bearer ${publicKey}`
		}
	};
	const { data } = await axios.get(createApiUrl(`/panel-type/exists/${encodeURIComponent(panelKey)}`), config);
	return data;
}

async function getDesignByExternalID(externalID: string, publicKey: string) {
	const config = {
		headers: {
			'Authorization': `Bearer ${publicKey}`
		}
	};
	const { data } = await axios.get(createApiUrl(`/designs/designByExternalID/${externalID}`), config);
	return data;
}

async function getDesignMetdaDataByExternalId(externalID: string, publicKey: string) {
	const config = {
		headers: {
			'Authorization': `Bearer ${publicKey}`
		}
	};
	// change it to uuid if it exists
	const { data } = await axios.get(createApiUrl(`/designs/get-design-meta-data/${externalID}`), config);
	return data;
}

async function getDesignMetaDataByUUID(uuid: string, publicKey: string) {
	const config = {
		headers: {
			'Authorization': `Bearer ${publicKey}`
		}
	};
	// change it to uuid if it exists
	const { data } = await axios.get<DesignWithoutRelations>(createApiUrl(`/designs/get-design-meta-data-by-uuid/${uuid}`), config);
	return data;
}

async function getThemeData(publicKey: string) {
	const config = {
		headers: {
			'Authorization': `Bearer ${publicKey}`
		}
	};
	// change it to uuid if it exists
	const { data } = await axios.get<DesignWithoutRelations>(createApiUrl('/organization'), config);
	return data;
}

export {
	verifyPublicKey,
	verifyPanelKey,
	getLatLng,
	getDesignByExternalID,
	getDesignMetaDataByUUID,
	getDesignMetdaDataByExternalId,
	getThemeData
};