import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type WarningModal = {
	open: boolean,
	warningMessage: string,
	selectedRoofIdx?: string,
	noOfPanels?: number,
};

const initialState: { data: WarningModal } = {
	data: {
		open: false,
		warningMessage: ''
	}
};

export const warningModalSlice = createSlice({
	name: 'warningModalProvider',
	initialState,
	reducers: {
		toggleWarningModalState(
			state,
			action: PayloadAction<{ open: boolean, warningMessage: string, selectedRoofIdx?: string, noOfPanels?: number }>
		) {
			state.data.open = action.payload.open;
			state.data.warningMessage = action.payload.warningMessage;
			if (action.payload?.selectedRoofIdx != undefined || action.payload?.selectedRoofIdx != null)
				state.data.selectedRoofIdx = action.payload.selectedRoofIdx;
			if (action.payload?.noOfPanels != undefined || action.payload?.noOfPanels != null)
				state.data.noOfPanels = action.payload.noOfPanels;
		}
	}
});

export const { toggleWarningModalState } = warningModalSlice.actions;

export default warningModalSlice.reducer;