import { ModalPopup, Modalbutton, ModalbuttonNo, ModalbuttonWrap, ModalcontentInfo } from 'components/OffsetModal/styles';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { AppDispatch, RootState } from 'store';
import { setProvider } from 'store/slices/ToolSlice';
import { fetchIrradianceImages } from 'store/slices/IrradianceSlice';
import { changePanel } from 'store/slices/RoofDataSlice/roofDataSlice';
import { IMAGERY_PROVIDER } from '../../constants';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';

export default function SwitchProvider() {
	const { selectedProvider } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { uuid, panel, roofDataChanging } = useSelector((state: RootState) => ({
		...state.roofData.data,
		roofDataChanging: state.roofData.configuringNewPanel || state.roofData.loading || state.roofData.switchingImageryProvider
	}));
	const [tempProvider, setTempProvider] = useState(selectedProvider);
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch<AppDispatch>();
	const allQueriesRunningInBackGround = useQueriesRunningInBackGround();
	const energyQueriesInProgrss = useQueriesRunningInBackGround(['save', `monthlyDetails-${uuid}`, `hourlyEnergy-${uuid}`]);
	const disableSelect = roofDataChanging || energyQueriesInProgrss || allQueriesRunningInBackGround;

	const handleChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
		const currentProvider = e.target.value as ImagerySource;
		setOpen(true);
		setTempProvider(currentProvider);
	};
	const message = 'This will reset all your progress on the map. Are you sure you want to continue?';
	const handleConfirmation = async (e:any) => {
		if(e.target.textContent === 'No') {
			dispatch(setProvider({currentProvider: selectedProvider}));
			setOpen(false);
			return;
		}
		setOpen(false);
		dispatch(setProvider({currentProvider: tempProvider}));
		await dispatch(changePanel({ uuid, panelKey: panel.key, source: tempProvider, switchingSource: true }));
		dispatch(fetchIrradianceImages({ uuid }));
	};

	return (
		<>
			<div className='switchMapping' style={{display:'flex',alignItems:'center', position: 'relative'}}>
				<svg style={{position: 'absolute',paddingLeft:'5px',color:'#a1a1a1',marginLeft: '7px',
					left: '0px'}} width="27" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M18.8372 12.186V1.60465C18.8372 1.27071 18.5665 1 18.2326 
					1H1.60465C1.27071 1 1 1.27071 1 1.60465V13.3953C1 13.7293 1.27071 14 1.60465 
					14H6.44186M18.8372 12.186V13.3953C18.8372 13.7293 18.5665 14 18.2326 14H6.44186M18.8372 
					12.186L15.2093 8.55814C14.3023 7.65116 13.2442 7.19767 11.8837 8.55814L6.44186 14" stroke="#4A525A" strokeWidth="1.5"/>
					<circle cx="5.83739" cy="5.83739" r="1.81395" stroke="#4A525A" strokeWidth="1.5"/>
				</svg>
				<select
					style=
						{{
							cursor: disableSelect ? 'not-allowed' : 'pointer',
							padding: selectedProvider === IMAGERY_PROVIDER.NEARMAP ?'6px 24px 6px 40px':'6px 36px 6px 40px',
							// border: '1px solid #ccc',
							// borderRadius: '4px',
							fontSize: '14px',
							backgroundColor: '#fff',
							color: '#223349',
							fontFamily: 'Inter',
							background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFF',
							border: '1px solid #E4E4E4',
							boxShadow:' 0px 2.25911px 4px rgba(100, 100, 100, 0.12)',
							borderRadius:' 91.4939px',
							height: '36px',
							appearance: 'none',
						}}
					value={selectedProvider}
					onChange={handleChange}
					disabled={disableSelect}
				>
					<option value={IMAGERY_PROVIDER.SUNROOF}>Google Maps HD</option>
					<option value={IMAGERY_PROVIDER.NEARMAP}>Nearmap</option>
				</select>
				<svg style={{position: 'absolute',color:'#a1a1a1',right: '16px',pointerEvents:'none'}} 
					width="12" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1 1L5 5L9 1" stroke="#64686A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
			</div>
			<Popup
				contentStyle={{ width: '560px', borderRadius: '4px' }}
				open={open}
				closeOnDocumentClick={false}
				onClose={() => {
					if (open)
						setOpen(false);
				}}
			>
				<ModalPopup>
					<ModalcontentInfo>
						{message}
					</ModalcontentInfo>

					<ModalbuttonWrap>
						<Modalbutton onClick={handleConfirmation}>
							Yes
						</Modalbutton>
						<ModalbuttonNo onClick={handleConfirmation}>
							No
						</ModalbuttonNo>
					</ModalbuttonWrap>
				</ModalPopup>
			</Popup>
		</>
	);
}